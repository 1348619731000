import { Component, Input, OnChanges } from '@angular/core';
import { DataSource, MatchmakingRankType } from '../../../../leetify-shared-utils/enums';

@Component({
	selector: 'app-rank-icon',
	templateUrl: './rank-icon.component.html',
	styleUrls: ['./rank-icon.component.scss'],
})
export class RankIconComponent implements OnChanges {
	@Input() protected readonly dataSource: DataSource.FACEIT | DataSource.GAMERS_CLUB | DataSource.MATCHMAKING | DataSource.MATCHMAKING_WINGMAN | 'premier_rating' | 'premier';
	@Input() protected readonly rankType: MatchmakingRankType | null = null;
	@Input() protected readonly showTooltip = true;
	@Input() protected readonly skillLevel: number;

	protected iconUrl: string;
	protected isPremierRating = false;
	protected label: string;

	public ngOnChanges(): void {
		this.isPremierRating = this.rankType === MatchmakingRankType.CS2_COMPETITIVE
			|| this.dataSource === 'premier_rating'
			|| this.dataSource === 'premier'
			|| (this.skillLevel > 20 && this.dataSource === DataSource.MATCHMAKING);
		if (this.isPremierRating) return;
	}
}
