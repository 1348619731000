import { NgModule } from '@angular/core';

import { AtomsModule } from 'src/components/atoms/atoms.module';

import { CommonNavComponent } from './nav.component';
import { CommonTopnavComponent } from '../topnav/topnav.component';
import { CommonPlayerSearchComponent } from '../topnav/player-search/player-search.component';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { CommonUserMenuComponent } from '../topnav/user-menu/user-menu.component';
import { CommonNotificationsComponent } from '../topnav/notifications/notifications.component';
import { CommonCriticalWarningComponent } from '../topnav/critical-warning/critical-warning.component';
import { CriticalWarningsService } from 'src/components/services/critical-warnings/critical-warnings.service';
import { CommonFollowRequestComponent } from '../topnav/follow-request/follow-request.component';
import { CommonFollowButtonComponent } from '../follow-button/follow-button.component';

// TODO: Split nav module into nav and topnav module
const components = [CommonFollowRequestComponent, CommonCriticalWarningComponent, CommonNotificationsComponent, CommonNavComponent, CommonTopnavComponent, CommonPlayerSearchComponent, CommonUserMenuComponent, CommonFollowButtonComponent];

@NgModule({
	declarations: components,

	imports: [AtomsModule, NgbTooltip],

	exports: components,

	providers: [CriticalWarningsService],
})
export class NavModule {
	//
}
