import { Component, Input } from '@angular/core';

@Component({
	selector: 'app-social-notification',
	templateUrl: './social-notification.component.html',
	styleUrls: ['./social-notification.component.scss'],
})
export class SocialNotificationWidget {
	@Input() public socialNotification: any; // placeholder for now

	public constructor(
	) {
	}

}
