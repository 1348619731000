import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { PosthogTrackingValue } from 'leetify-shared-utils/enums';
import { AuthService } from '../auth/auth.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class TrackerService {
  constructor(private http: HttpClient) {}

  public track(name: PosthogTrackingValue, data: Record<string, any> = {}): void {
    const headers = AuthService.getHeaders();
    this.http.post(`${environment.csApiUrl}/api/track/${name}`, data, { headers }).subscribe();
  }
}
