import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppLayoutComponent } from './layouts/app-layout/app-layout.component';
import { AuthGuard } from './auth/auth.guard';
import { PlainLayoutComponent } from './layouts/plain-layout/plain-layout.component';
import { OnboardingLayoutComponent } from './layouts/onboarding-layout/onboarding-layout.component';
import { ErrorNotFoundComponent } from './pages/error/not-found/not-found.component';
import { SharedPostComponent } from './pages/user/feed/shared-post.component';
import { UserResolver } from './resolvers/user.resolver';
import { AccountComponent } from './pages/user/account/account.component';

const routes: Routes = [
  // user pages
  {
    path: '',
    component: AppLayoutComponent,
    canActivate: [AuthGuard],
    resolve: { user: UserResolver },
    children: [
      { path: '', loadChildren: () => import('src/app/pages/user/feed/feed.module').then((m) => m.FeedModule) },
      { path: 'account', loadChildren: () => import('src/app/pages/user/account/account.module').then((m) => m.AccountModule) },
    ],
  },

  // publicly available app pages
  {
    component: AppLayoutComponent,
    path: 'public',
    canActivate: [AuthGuard],

    children: [
      { path: ':game/:matchId/:userId', component: SharedPostComponent },
    ],
  },

  // onboarding
  {
    path: 'onboarding',
    component: OnboardingLayoutComponent,
  },
  {
    path: 'account',
    component: AccountComponent,
  },

  // errors
  {
    path: '',
    component: PlainLayoutComponent,
    children: [{ path: '**', component: ErrorNotFoundComponent }],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: 'enabledNonBlocking',
      paramsInheritanceStrategy: 'always',
      scrollPositionRestoration: 'enabled',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {
  //
}
