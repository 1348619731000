import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate{
  public constructor(
    private readonly authService: AuthService,
    private readonly router: Router,
  ) {
    //
  }

  public async canActivate(route: ActivatedRouteSnapshot, router: RouterStateSnapshot): Promise<boolean | UrlTree> {
    const url = router.url;

    if (this.authService.isAuthenticated()) {
      console.log('authenticated');

      if (url.startsWith('/public/')) return this.router.navigateByUrl(router.url.replace(/^\/public\//, '/feed/'));

      return true;
    }

    console.log('auth guard - not authenticated');

    if (url.startsWith('/public/')) return true;

    const isRouteAvailablePublicly = url.startsWith('/feed');
    if (isRouteAvailablePublicly) return this.router.navigateByUrl(router.url.replace(/^\/feed\//, '/public/'));

    window.location.href = `${environment.frontendBaseUrl}/auth`;

    return false;
  }
}
