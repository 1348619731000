import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { ViewHelper } from '../../../../helpers/view.helper';
import {
  AchievementDTO,
  CsMatchDetailsDTO,
  CsStatsDTO,
  DeadlockMatchDetailsDTO,
  DeadlockStatsDTO,
  GameName,
  HighlightDTO,
  HomepageDTO,
  LoLMatchDetailsDTO,
  LolStatsDTO,
  MatchResult,
  ParticipantDTO,
  TeamDTO
} from '../../../../../../leetify-home-dtos/homepage-dto';
import { environment } from '../../../../../environments/environment';
import { User } from '../../../../models/user.model';
import { PostService } from '../../../../services/post.service';
import { Like } from '../../../../models/like.model';
import { Description } from '../../../../models/description.model';
import { DataSource } from '../../../../../../leetify-shared-utils/enums';

@Component({
  selector: 'post-card',
  templateUrl: './post.component.html',
  styleUrls: ['./post.component.scss'],
})

export class PostComponent implements OnInit {
  @Input() user: User = null;
  @Input() post: HomepageDTO;
  @Input() teamId: string | number;
  @Input() shared: boolean;
  @Input() descriptions: Description[];
  @Output() copiedToClipboard = new EventEmitter<void>();

  protected DEFAULT_DESCRIPTION = 'Leave a comment for your followers';
  protected EDIT_CHAR = ' '; // todo find a better solution
  protected achievements: AchievementDTO[];
  protected highlight: HighlightDTO[];
  protected date: { month?: string, time?: string } = {};
  protected gameIcon: string;
  protected gameResult: string;
  protected isLiked: boolean;
  protected likeIcon: string;
  protected players: ParticipantDTO[] = [];
  protected team: TeamDTO;
  protected rankIcon: string;
  protected lolStats: LolStatsDTO;
  protected csStats: CsStatsDTO;
  protected deadlockStats: DeadlockStatsDTO;
  protected username: string;
  protected userTag: string;
  protected activeTab: number = 0;

  protected cardTitle: string;
  protected cardSubtitle: string;
  protected kills: number;
  protected assists: number;
  protected deaths: number;
  protected splashImage: string;
  protected splashStyle: string;
  protected gameLength: string;
  protected mainGameStat: string;
  protected icon: string;
  protected ownTeamScore: number;
  protected enemyTeamScore: number;
  protected postDescriptions: string[] = [];
  protected newDescription: string = this.DEFAULT_DESCRIPTION;
  protected usersWhoLiked: Like[] = [];
  protected isKebabOpen: boolean = false;
  protected isEditingDescription: boolean = false;
  protected fontSize: number[] = [];
  protected isDescriptionOpen: boolean = true;
  protected isInitialDescriptionLoaded: boolean = false;
  protected rankName: string;

  constructor(
    protected readonly postService: PostService,
  ) {}

  protected isTabSelected(index: number) {
    return this.activeTab === index;
  }

  protected activateTab(index: number) {
    if (this.activeTab === index) return;

    this.activeTab = index;

    // switch to local save of descriptions
    this.isInitialDescriptionLoaded = true;

    // remap data of the selected player tab
    this.mapGeneralData(index);

    // if there is a description, open description animation
    if (this.postDescriptions[index] && this.postDescriptions[index] !== this.EDIT_CHAR) {
      this.openDescription();
    }
    // else close description animation
    else {
      this.closeDescription();
    }

    // if the post is a lol post
    if (this.isLeague()) {
      this.mapLeagueData(index);
    }
    // if the post is a cs post
    if (this.isCs()) {
      this.mapCsData(index);
    }
    // if the post is a deadlock post
    if (this.isDeadlock()) {
      this.mapDeadlockData(index);
    }
  }

  protected isMyPost() {
    if (this.user) {
      // if the selected player tab is the logged-in user
      if (this.players[this.activeTab].leetifyUserId === this.user.id) {
        return true;
      }
    }
    return false;
  }

  protected isLeague() {
    return this.post.gameName === GameName.LEAGUE;
  }

  protected isDeadlock() {
    return this.post.gameName === GameName.DEADLOCK;
  }

  protected isCs() {
    return this.post.gameName === GameName.CS;
  }

  protected isPremier() {
    if (!this.isCs())
      return false;
    const matchDetails = this.post.matchDetails as CsMatchDetailsDTO;
    return matchDetails.rankType === 11;
  }

  protected changeLikeIcon() {
    if (this.isLiked) {
      this.likeIcon = 'assets/icons/liked.svg';
    } else {
      this.likeIcon = 'assets/icons/like.svg';
    }
  }

  protected async likePost() {
    // get participants ids
    const participantIds = this.players.map((participant) => participant.leetifyUserId);

    // request like or unlike
    await this.postService.likePostRequest(this.post.gameName, this.post.matchId, participantIds);

    // if post is already liked -> dislike
    if (this.isLiked) {
      this.isLiked = !this.isLiked;
      // remove user from usersWhoLiked
      this.usersWhoLiked = this.usersWhoLiked.filter(user => user.leetifyUserId !== this.user.id);
    }
    // if post is not liked -> like
    else {
      this.isLiked = !this.isLiked;
      // add user to usersWhoLiked
      const user: Like = {
        leetifyUserId: this.user.id,
        userAvatar: this.user.steamAvatarUrl,
        userNickname: this.user.nickname || this.user.steamNickname,
      }
      this.usersWhoLiked.push(user);
    }

    // change icon
    this.changeLikeIcon();
    // put user first
    this.makeLoggedUserFirstInLikedList();
  }

  public getLeagueUserAvatar(avatar: string) {
    return ViewHelper.getAssetUrl('profile-icon', avatar, 32, 32);
  }

  protected formatDate(matchFinishedAt: string) {
    const date = new Date(matchFinishedAt);

    const monthDayFormatter = new Intl.DateTimeFormat('en-US', {
      month: 'short',
      day: 'numeric'
    })

    const timeFormatter = new Intl.DateTimeFormat('en-US', {
      hour: 'numeric',
      minute: 'numeric',
      hour12: false
    })

    const today = monthDayFormatter.format(new Date());

    // adjusted based on timezone
    this.date.month = monthDayFormatter.format(date) === today ? 'Today' : monthDayFormatter.format(date);
    this.date.time = timeFormatter.format(date);
  }

  protected mapGeneralData(activeTab: number) {
    this.username = this.players[activeTab].username;

    // set description
    if (!this.postDescriptions[activeTab]) {
      this.getDescription(activeTab).then();
    }

    this.achievements = this.players[activeTab].achievements;
    this.highlight = this.players[activeTab].highlights;
  }

  protected mapLeagueData(activeTab: number) {
    this.lolStats = this.players[activeTab].stats as LolStatsDTO;

    this.userTag = this.lolStats.userTag;
    const rank = this.lolStats.rank as string;

    if (this.lolStats.rank) {
      this.rankIcon = ViewHelper.getRankIcon(rank);
    } else {
      this.rankIcon = ViewHelper.getRankIcon('UNRANKED');
    }

    const matchDetails = this.post.matchDetails as LoLMatchDetailsDTO;
    this.icon = ViewHelper.getRoleIcon(this.lolStats.role);

    const splashUrl = `/champion/centered/${this.lolStats.championName}.jpg`
    this.splashImage = ViewHelper.getAssetUrl('champion', splashUrl, 1280, 488);
    this.splashStyle = `linear-gradient(rgba(42, 39, 49, 0.70), rgba(42, 39, 49, 0.70)), url(${this.splashImage}) no-repeat top right/cover`;

    this.cardTitle = this.lolStats.championName;
    this.cardSubtitle = ViewHelper.getQueueType(matchDetails.queueId);
    this.gameLength = ViewHelper.formatGameDuration(matchDetails.matchDuration);
    this.kills = this.lolStats.kills;
    this.assists = this.lolStats.assists;
    this.deaths = this.lolStats.deaths;
    this.mainGameStat = ViewHelper.formatNumbersAsK(this.lolStats.totalDmg);

    this.rankName =  rank ? ViewHelper.getRankText('', rank) : ViewHelper.getRankText('', 'UNRANKED');
  }

  protected mapCsData(activeTab: number){
    this.csStats = this.players[activeTab].stats as CsStatsDTO;

    const matchDetails = this.post.matchDetails as CsMatchDetailsDTO;

    this.rankIcon = ViewHelper.getRankImagePath(matchDetails.dataSource, this.csStats.rank as number, false);

    this.icon = ViewHelper.getMapIcon(matchDetails.mapName);

    this.splashImage =  ViewHelper.getMapImage(matchDetails.mapName);
    this.splashStyle = `linear-gradient(rgba(42, 39, 49, 0.70), rgba(42, 39, 49, 0.70)), url(${this.splashImage}) no-repeat top right/cover`;

    this.cardTitle = ViewHelper.getFormattedMapName(matchDetails.mapName);
    this.cardSubtitle = String(matchDetails.dataSource).charAt(0).toUpperCase() + String(matchDetails.dataSource).slice(1);

    this.ownTeamScore = this.team.ownScore;
    this.enemyTeamScore = this.team.enemyScore;
    this.kills = this.csStats.kills;
    this.assists = this.csStats.assists;
    this.deaths = this.csStats.deaths;
    this.mainGameStat = String(ViewHelper.formatLeetifyRating(this.players[activeTab].leetifyRating));

    this.rankName = ViewHelper.getRankLabel(matchDetails.dataSource as DataSource, this.csStats.rank as number, matchDetails.rankType);
  }

  protected mapDeadlockData(activeTab: number) {
    this.deadlockStats = this.players[activeTab].stats as DeadlockStatsDTO;

    const matchDetails = this.post.matchDetails as DeadlockMatchDetailsDTO;
    this.deadlockStats = this.players[activeTab].stats as DeadlockStatsDTO;

    this.splashImage =  `assets/images/placeholders/${this.deadlockStats.heroName.toLowerCase()}-splash.png`;
    this.splashStyle = `linear-gradient(rgba(42, 39, 49, 0.70), rgba(42, 39, 49, 0.70)), url(${this.splashImage}) no-repeat top right/cover`;

    this.cardTitle = this.deadlockStats.heroName;
    this.cardSubtitle = this.team.teamId as string;
    this.gameLength = ViewHelper.formatGameDuration(matchDetails.matchDuration);
    this.kills = this.deadlockStats.kills;
    this.assists = this.deadlockStats.assists;
    this.deaths = this.deadlockStats.deaths;
    this.mainGameStat = ViewHelper.formatNumbersAsK(this.deadlockStats.totalSouls);
  }

  protected calculateWidthOfTabs(index: number) {
    const maxWidth = 679;
    // if we have more than 3 players, we set a fixed size for them
    if (this.players.length > 3) {
      // the selected tab will have a bigger width
      const selectedTabWidth = 240;
      const unselectedTabsWidth = (maxWidth - selectedTabWidth) / (this.players.length - 1);
      if (index === this.activeTab) {
        return selectedTabWidth + 'px';
      } else {
        return unselectedTabsWidth + 'px';
      }
    }
    // else divide equally
    return maxWidth / this.players.length + 'px';
  }

  protected async copyLink(): Promise<void> {
    const url = `${environment.frontendBaseUrl}/home/feed/${this.post.gameName}/${this.post.matchId}/${this.user.id}`;

    await navigator.clipboard.writeText(url);
    this.copiedToClipboard.emit();
  }

  @HostListener('document:click', ['$event'])
  onClickOutside(event: Event): void {
    const targetElement = event.target as HTMLElement;

    const kebabId = this.post.matchId;
    const textAreaId = 'textArea' + this.post.matchId;
    const editButtonId = 'edit' + this.post.matchId;

    // close kebab menu if is clicked outside of it
    if (targetElement && targetElement.id !== kebabId) {
      this.isKebabOpen = false;
    }

    // close text area if is clicked outside of it
    if (targetElement && this.isEditingDescription) {
      if (targetElement.id !== textAreaId && targetElement.id !== editButtonId){
        this.onEditDescriptionCancel();
      }
    }
  }

  protected toggleKebabMenu() {
    this.isKebabOpen = !this.isKebabOpen;
  }

  protected openDescription() {
    if(this.isDescriptionOpen) {
      this.isDescriptionOpen = false;
    }
    setTimeout(() => {
      this.isDescriptionOpen = true;

    }, 10);
  }

  protected closeDescription() {
    this.isDescriptionOpen = false;
  }

  protected adjustFontSizeForDescription(description: string, index: number) {
    const maxLength = 20; // number to divide the max word length
    const minFontSize = 18;
    const maxFontSize = 22;

    if (description) {
      this.fontSize[index] = Math.max(minFontSize, maxFontSize - Math.floor(description.length / maxLength));
    }
  }

  protected getInitialLoadOfDescriptions() {
    if (this.descriptions && this.descriptions.length !== 0) {
      // set description for selected user and match
      const desc = this.descriptions.find(description => (description.leetifyUserId === this.players[this.activeTab].leetifyUserId) && (description.matchId === this.post.matchId));
      if (desc) {
        // we only care about the first tab for the initial load
        this.adjustFontSizeForDescription(desc.description, 0);
        return desc.description;
      }
    }
  }

  protected async getDescription(index: number) {
    // if the description was not edited by user
    if (!this.postDescriptions[index] && this.descriptions) {
      const foundDescription = this.descriptions.find(description => description.leetifyUserId === this.players[index].leetifyUserId);

      if (foundDescription) {
        this.postDescriptions[index] = foundDescription.description;

        // adjust font size based on length
        this.adjustFontSizeForDescription(this.postDescriptions[index], index);
      }
    }
  }

  protected onEditDescription() {
    this.isEditingDescription = true;

    this.adjustFontSizeForDescription(this.newDescription, this.activeTab);
  }

  protected async onFinishEditingDescription() {
    this.isEditingDescription = false;

    // switch to local save of description if description was edited, so we don't keep the description that comes from API
    this.isInitialDescriptionLoaded = true;

    // if description is deleted
    if (!this.newDescription) {
      await this.postService.deleteDescriptionRequest(this.post.gameName, this.post.matchId);
      // set to EDIT_CHAR to avoid reloading old description from BE
      this.postDescriptions[this.activeTab] = this.EDIT_CHAR;

      const indexToRemove = this.descriptions.findIndex(description => description.leetifyUserId === this.players[this.activeTab].leetifyUserId);
      this.descriptions.splice(indexToRemove, 1);

      // close description animation
      this.closeDescription();

      return;
    }

    // save new description to db
    await this.postService.editDescriptionRequest(this.post.gameName, this.post.matchId, this.newDescription);

    // set the current description as the new description
    this.postDescriptions[this.activeTab] = this.newDescription;

    // open description animation
    this.openDescription();
  }

  protected onEditDescriptionCancel() {
    this.isEditingDescription = false;
  }

  protected onEditDescriptionFocus() {
    // if when clicking on textarea, the description is the default placeholder, delete it
    if (this.newDescription === this.DEFAULT_DESCRIPTION) {
      this.newDescription = ''
    }
  }

  protected async getLikes() {
    const response = await this.postService.reloadUsersWhoLikedPost(this.post.gameName, this.post.matchId);
    for (const resp of response) {
      const user: Like = {
        leetifyUserId: resp.leetify_user_id,
        userAvatar: resp.user_avatar,
        userNickname: resp.user_nickname,
      }
      // if post is already liked, change icon
      if (this.user && user.leetifyUserId === this.user.id) {
        this.isLiked = true;
        this.changeLikeIcon();
      }
      this.usersWhoLiked.push(user);
    }
    this.makeLoggedUserFirstInLikedList();
  }

  protected makeLoggedUserFirstInLikedList() {
    if (this.user) {
      const index = this.usersWhoLiked.findIndex(user => user.leetifyUserId === this.user.id);

      if (index !== -1) {
        const [user] = this.usersWhoLiked.splice(index, 1);
        this.usersWhoLiked.unshift(user);
      }
    }
  }

  protected getUsersWhoLiked() {
    let returningString: string = '';
    // number of user nicknames to show before showing the total number of users
    const numberOfUsersToShow = 2;
    let connectionWord = '';

    if (!this.user) {
      return `${this.usersWhoLiked.length} users liked this post`;
    }

    if (this.usersWhoLiked.length === 2) {
      connectionWord = ' and ';
    } else if (this.usersWhoLiked.length > 2) {
      connectionWord = ', ';
    }

    let ct = 0;
    if (this.isLiked) {
      returningString = 'You';
      ct++;
    }

    for (const user of this.usersWhoLiked) {
      // exclude logged in user
      if (user.userNickname !== this.user.nickname && user.userNickname !== this.user.steamNickname) {
        if (ct === 0) {
          returningString += `${user.userNickname}`
        } else {
          returningString += `${connectionWord}${user.userNickname}`;
        }
        ct++;
      }
      if (ct >= numberOfUsersToShow) break;
    }

    if (this.usersWhoLiked.length <= 2) {
      returningString += ` liked this post`;
    } else {
      // add the number of users who liked minus the ones we added nicknames for
      returningString += ` and ${this.usersWhoLiked.length - ct} other liked this post`;
    }

    return returningString;
  }

  public ngOnInit(): void {
    // likes
    this.likeIcon = 'assets/icons/like.svg';
    this.getLikes().then();

    // get participants for the post and team
    this.players = this.post.teams.find((team) => team.teamId === this.teamId).participants;
    this.team = this.post.teams.find((team) => team.teamId === this.teamId);

    // static post stats
    this.gameIcon = `assets/icons/${this.post.gameName}.svg`;

    if (this.team.matchResult === MatchResult.TIE) {
      this.gameResult = 'tie';
    } else {
      this.gameResult = this.team.matchResult === MatchResult.WIN ? 'win' : 'loss';
    }

    // date
    this.formatDate(this.post.matchFinishedAt);

    // map stats
    this.mapGeneralData(this.activeTab);
    if (this.isLeague()){
      this.mapLeagueData(this.activeTab);
    } else if (this.isCs()) {
      this.mapCsData(this.activeTab);
    } else if (this.isDeadlock()) {
      this.mapDeadlockData(this.activeTab);
    }
  }
}
