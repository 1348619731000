import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AuthService } from '../auth/auth.service';

export interface SteamNotificationResponse {
  notificationsEnabled: boolean;
}

export interface SteamBotClientInfo {
  botSteam64Id: string;
  inviteLink: string;
  url: string;
}

@Injectable({
  providedIn: 'root',
})
export class SteamBotService {
  protected readonly steamNotificationsEnabledSource = new Subject<boolean>();
  public readonly steamNotificationsEnabled$ = this.steamNotificationsEnabledSource.asObservable();

  protected readonly availableSteamBotSource = new Subject<SteamBotClientInfo>();
  public readonly availableSteamBot$ = this.availableSteamBotSource.asObservable();

  public constructor(protected readonly http: HttpClient) {
    //
  }

  public reloadSteamNotificationsEnabled(): void {
    const headers = AuthService.getHeaders();
    this.http.get<SteamNotificationResponse>(`${environment.csApiUrl}/api/user/steam/notifications`, { headers }).subscribe((response) => {
      this.steamNotificationsEnabledSource.next(response.notificationsEnabled);
    });
  }

  public getAvailableSteamBot(): void {
    const headers = AuthService.getHeaders();
    this.http.post<SteamBotClientInfo>(`${environment.csApiUrl}/api/steam/steam-bot-invite-link`, {}, { headers }).subscribe(
      (response) => {
        this.availableSteamBotSource.next(response);
      },
      () => {
        this.availableSteamBotSource.next(null);
      }
    );
  }
}
