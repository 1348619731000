<header>
  <h1>Account Settings</h1>
</header>

<section class="card">
  <div class="card-header">
    <i class="mdi mdi-account"></i>
    <h2>Profile</h2>
  </div>

  <div class="card-body">
    <div class="profile">
      <div class="details-wrapper">
        <div class="profile-picture">
          <img class="edit-icon" src="assets/icons/edit-pen.svg" />
          <img (click)="profilePictureInput.click()" class="avatar" [src]="profilePictureUrl || ViewHelper.PROFILE_PICTURE_FALLBACK" alt="" (error)="onProfilePictureErrored($event)" />
          <input #profilePictureInput accept="image/*" type="file" id="profile-picture-file-input" (change)="uploadProfilePicture($event.target.files)" />
        </div>
        <div class="name">
          <label for="nicknameInput">Display name</label>
          <div class="nickname-input-wrapper">
            <input [(ngModel)]="nickname" #nicknameInput type="text" class="nickname-input" [value]="user?.nickname">
            <button (click)="triggerNicknameSave()" [disabled]="nicknameSyncRequestInProgress">
              Apply
            </button>
          </div>
          <div class="steam-sync">
            <button (click)="triggerSteamSync()" [disabled]="steamSyncRequestInProgress">
              <i class="mdi mdi-refresh"></i>
              Sync with Steam
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="user-details">
      <div *ngIf="user?.isProPlan" class="pro-status">
        <img class="pro-logo" src="assets/leetify/leetify-pro-icon-text.svg" alt="Leetify Pro" />

        <div class="expiry">
          valid until
          {{ user.proPlanExpiresAt | date: 'yyyy-MM-dd' }}
        </div>

        <a *ngIf="user.isCollector" href="/founder/{{ user.id }}" target="_blank" class="founder-link">
          <i class="leet-leetify mr-2"></i>
          Founder Profile
        </a>
      </div>

      <div class="id-item">
        Email Address:
        <input #emailIdInput type="email" class="id-input" [value]="user?.email" (focus)="copyLink(emailIdInput)" readonly />
      </div>

      <div class="id-item">
        Steam ID:
        <input #steam64IdInput type="text" class="id-input" [value]="user?.steam64Id" (focus)="copyLink(steam64IdInput)" readonly />
      </div>

      <div class="id-item">
        Support ID:
        <input #userIdInput type="text" class="id-input" [value]="user?.id" (focus)="copyLink(userIdInput)" readonly />
      </div>
    </div>

    <section class="header-image">
      <div class="blurb">
        <h3>Profile Cover Image</h3>

        <p>This cover image is used in your Profile, Mini Profile, and in the Session view.</p>
      </div>

      <div class="current-image-wrapper">
        <img
          [src]="headerImageUrl || ViewHelper.PLAYER_HEADER_FALLBACK"
          alt="Your current Profile Cover Image"
          (error)="onPlayerHeaderErrored($event)"
        />

        <div>1200&nbsp;x&nbsp;350&nbsp;px</div>
      </div>

      <div class="upload-wrapper">
        <label class="select-file-button" for="header-image-file-input"> Upload Cover Image </label>

        <input type="file" id="header-image-file-input" (change)="uploadHeaderImage($event.target.files)" />
      </div>

      <div class="delete-wrapper">
        <button class="delete-image-button" (click)="deleteHeaderImage()">Delete Cover Image</button>
      </div>
    </section>
  </div>
</section>

<section class="card">
  <div class="card-header">
    <svg viewBox="0 0 127.14 96.36">
      <path
        d="M107.7 8.07A105.15 105.15 0 0 0 81.47 0a72.06 72.06 0 0 0-3.36 6.83 97.68 97.68 0 0 0-29.11 0A72.37 72.37 0 0 0 45.64 0a105.89 105.89 0 0 0-26.25 8.09C2.79 32.65-1.71 56.6.54 80.21a105.73 105.73 0 0 0 32.17 16.15 77.7 77.7 0 0 0 6.89-11.11 68.42 68.42 0 0 1-10.85-5.18c.91-.66 1.8-1.34 2.66-2a75.57 75.57 0 0 0 64.32 0 37.7 37.7 0 0 0 2.66 2 68.68 68.68 0 0 1-10.87 5.19 77 77 0 0 0 6.89 11.1 105.25 105.25 0 0 0 32.19-16.14c2.64-27.38-4.51-51.11-18.9-72.15ZM42.45 65.69C36.18 65.69 31 60 31 53s5-12.74 11.43-12.74S54 46 53.89 53s-5.05 12.69-11.44 12.69Zm42.24 0C78.41 65.69 73.25 60 73.25 53s5-12.74 11.44-12.74S96.23 46 96.12 53s-5.04 12.69-11.43 12.69Z"
      />
    </svg>

    <h2>CS Discord</h2>
  </div>

  <div class="card-body">
    <leetify-common-discord-connect-button></leetify-common-discord-connect-button>
  </div>
</section>

@if(user?.onboardingStatusLOL) {
  <app-lol-settings></app-lol-settings>
}

@if(user?.steam64Id) {
  <section class="card">
    <div class="card-header">
      <i class="mdi mdi-steam"></i>
      <h2>Steam Notifications</h2>
    </div>
  
    <div class="card-body">
      <p>When we finish analysing a match, we will tell the Steam bot to send you a message with a link to that latest match.</p>
  
      <hr />
  
      <img
        *ngIf="steamNotificationsEnabled === undefined || (steamNotificationsEnabled === false && !steamApplicationUrl)"
        class="spinner"
        src="/assets/images/spinner.svg"
        alt="Loading..."
      />
  
      <ng-container *ngIf="steamNotificationsEnabled === false && steamApplicationUrl">
        <p>To add the Steam notification bot to your friends list, click one of the buttons below!</p>
  
        <div class="steam-bot-invite-controls">
          <a [href]="steamBotInviteLink" class="btn btn-primary mb-3" (click)="trackSteamBotAdded()" target="_blank">
            <i class="fab fa-steam mr-1"></i>
            Add to friends list
          </a>
        </div>
  
        <p class="small text-muted m-0">
          If the Quick Invite Link above doesn't work, you can also
          <a [href]="steamCommunityUrl" (click)="trackSteamBotAdded()" target="_blank">add the bot through your browser</a>, or
          <a [href]="steamApplicationUrl" (click)="trackSteamBotAdded()">through your Steam client.</a>
          <br />
          Adding through your Steam client will only work if you are on a computer that's logged in to the right account on Steam.
        </p>
      </ng-container>
  
      <ng-container *ngIf="steamNotificationsEnabled">
        <div class="steam-notifications-enabled-badge">
          <i class="mdi mdi-check"></i>
          Steam Notifications enabled
        </div>
  
        <p class="m-0">
          To disable them, simply find the bot on your Steam friends list and remove it.
          <br />
          (Don't worry, it doesn't have feelings!)
        </p>
      </ng-container>
    </div>
  </section>
}


@if(user?.steam64Id) {
  <app-privacy-controls></app-privacy-controls>
}

<app-account-billing [receipts]="receipts"></app-account-billing>

<section *ngIf="receipts.length" class="card">
  <div class="card-header">
    <i class="mdi mdi-receipt"></i>
    <h2>Receipts</h2>
  </div>

  <div class="card-body">
    <app-receipts [receipts]="receipts"></app-receipts>
  </div>
</section>

@if(user?.steam64Id) {
  <app-account-change-details></app-account-change-details>
}