import { S3_BASE_URL } from 'src/constants';
import { AchievementTier, DataSource, MatchmakingRankType } from '../../../leetify-shared-utils/enums';

export enum QueueType {
  SOLODUO_RANKED = 420,
  NORMAL_DRAFT = 400,
  FLEX_QUEUE = 440,
}

export class ViewHelper {
  static roleOrder = {
    top: 1,
    jungle: 2,
    middle: 3,
    adc: 4,
    support: 5,
  };

  public static readonly PLAYER_HEADER_FALLBACK = 'assets/images/player-header.svg';
	public static readonly PROFILE_PICTURE_FALLBACK = 'assets/images/anon.png';

  static getAssetUrl(assetType: string, asset: string | number, width: number, height: number): string | null {
    if (!asset || !assetType) return null;

    let urlToEncode: string = '';

    if (assetType === 'champion') {
      urlToEncode = `${S3_BASE_URL}${asset}`;
    } else if (assetType === 'championIcon') {
      const filename = typeof asset === 'string' ? asset.split('/').pop().split('.')[0] : asset;
      const filetype = 'jpg';
      urlToEncode = `${S3_BASE_URL}/champion/tiles/${filename}.${filetype}`;
    } else {
      urlToEncode = `${S3_BASE_URL}/${assetType}/${asset}.png`;
    }

    return `https://img.leetify.com/_/rs:fill:${width}:${height}/${btoa(urlToEncode)}`;
  }

  static formatGameDuration(seconds: number): string {
    const minutes = Math.floor(seconds / 60);
    const leftoverSeconds = seconds - minutes * 60;

    const minutesWithLeadingZero = minutes < 10 ? `0${minutes}` : `${minutes}`;
    const leftoverSecondsWithLeadingZero = leftoverSeconds < 10 ? `0${leftoverSeconds}` : `${leftoverSeconds}`;

    return `${minutesWithLeadingZero}:${leftoverSecondsWithLeadingZero}`;
  }

  static roundToNumber(num: number, roundTo: number): number {
    return Math.ceil(num / roundTo) * roundTo;
  }

  static formatNumbersAsK(number: number): string {
    if (number >= 1000) {
      const dividedNumber = (number / 1000).toFixed(1);
      return `${dividedNumber}k`;
    }
    return number.toString();
  }

  static formatLPChange(number: number): string {
    if (number === 0 || !number) return '-';

    const sign = number > 0 ? '+' : '';

    return sign + String(number);
  }

  public static getLPChangeTextColorClass(lpChange: number): string {
    if (lpChange === 0 || !lpChange) return '';
    if (lpChange < 0) return 'text-poor';
    return 'text-great';
  }

  static getRankIcon(rank: string): string {
    switch (rank) {
      case 'UNRANKED':
        return 'assets/icons/league/rank-icons/unranked.svg';
      case 'BRONZE':
        return 'assets/icons/league/rank-icons/bronze.svg';
      case 'SILVER':
        return 'assets/icons/league/rank-icons/silver.svg';
      case 'GOLD':
        return 'assets/icons/league/rank-icons/gold.svg';
      case 'PLATINUM':
        return 'assets/icons/league/rank-icons/platinum.svg';
      case 'EMERALD':
        return 'assets/icons/league/rank-icons/emerald.svg';
      case 'DIAMOND':
        return 'assets/icons/league/rank-icons/diamond.svg';
      case 'MASTER':
        return 'assets/icons/league/rank-icons/master.svg';
      case 'GRANDMASTER':
        return 'assets/icons/league/rank-icons/grandmaster.svg';
      case 'IRON':
        return 'assets/icons/league/rank-icons/iron.svg';
      case 'CHALLENGER':
        return 'assets/icons/league/rank-icons/challenger.svg';
    }
  }

  static getRankText(rank: string, tier: string): string {
    let rankText = '';
    let rankNumberText = '';
    switch (rank) {
      case 'I':
        rankNumberText = '1';
        break;
      case 'II':
        rankNumberText = '2';
        break;
      case 'III':
        rankNumberText = '3';
        break;
      case 'IV':
        rankNumberText = '4';
        break;
    }
    switch (tier) {
      case 'CHALLENGER':
        rankText = 'Challenger';
        break;
      case 'GRANDMASTER':
        rankText = 'Grandmaster';
        break;
      case 'MASTER':
        rankText = 'Master';
        break;
      case 'DIAMOND':
        rankText = 'Diamond ' + rankNumberText;
        break;
      case 'EMERALD':
        rankText = 'Emerald ' + rankNumberText;
        break;
      case 'PLATINUM':
        rankText = 'Platinum ' + rankNumberText;
        break;
      case 'GOLD':
        rankText = 'Gold ' + rankNumberText;
        break;
      case 'SILVER':
        rankText = 'Silver ' + rankNumberText;
        break;
      case 'BRONZE':
        rankText = 'Bronze ' + rankNumberText;
        break;
      case 'IRON':
        rankText = 'Iron ' + rankNumberText;
        break;
      case 'UNRANKED':
        rankText = 'Unranked';
        break;
    }
    return rankText;
  }

  static getRoleIcon(role: string): string {
    switch (role) {
      case 'top':
        return 'assets/icons/league/role-icons/Top.svg';
      case 'middle':
        return 'assets/icons/league/role-icons/Mid.svg';
      case 'adc':
        return 'assets/icons/league/role-icons/Bot.svg';
      case 'support':
        return 'assets/icons/league/role-icons/Support.svg';
      case 'jungle':
        return 'assets/icons/league/role-icons/Jungle.svg';
      case 'all':
        return 'assets/icons/league/role-icons/All.svg';
    }
  }

  static getRoleIcons(): string[] {
    return [this.getRoleIcon('top'), this.getRoleIcon('jungle'), this.getRoleIcon('middle'), this.getRoleIcon('adc'), this.getRoleIcon('support')];
  }

  static formatTimestampMinuteSeconds(timestamp: number) {
    const minutes = Math.floor(timestamp / 60);
    const seconds = Math.floor(timestamp % 60);

    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  }

  static getQueueType(queueId: number): string {
    if (queueId === QueueType.NORMAL_DRAFT) {
      return 'Normal Draft';
    } if (queueId === QueueType.SOLODUO_RANKED) {
      return 'Solo/Duo Ranked';
    } if (queueId === QueueType.FLEX_QUEUE) {
      return 'Flex Ranked'
    }
    return 'Unknown';
  }

  static getFormattedMapNameWithPrefix(mapName: string): string {
    if (!mapName) return '';

    const segments = mapName.split('/');
    return segments[segments.length - 1];
  }

  static getMapImage(mapName: string, small = true, variant = ''): string {
    return `assets/images/maps-webp/${small ? 'small/' : ''}${ViewHelper.getFormattedMapNameWithPrefix(mapName)}${variant}${small ? '_small' : ''}.webp`;
  }

  static getMapIcon(mapName: string): string {
    return `assets/images/map-icons/${ViewHelper.getFormattedMapNameWithPrefix(mapName)}.svg`;
  }

  static getRankImagePath(dataSource: string, rank: number, forcePng?: boolean): string {
    if (![DataSource.FACEIT, DataSource.MATCHMAKING, DataSource.GAMERS_CLUB, DataSource.MATCHMAKING_WINGMAN].includes(dataSource as DataSource) || rank === undefined || rank === null) return 'assets/images/rank-icons/na.png';

    let extension = dataSource === DataSource.FACEIT || dataSource === DataSource.GAMERS_CLUB ? 'svg' : 'png';
    if (forcePng) extension = 'png';

    return `assets/images/rank-icons/${dataSource}${rank}.${extension}`;
  }

  static formatLeetifyRating(number: number): string {
    if (number === null || number === undefined) return '-';
    if (number >= 0) return `+${(number * 100).toFixed(2)}`;
    return (number * 100).toFixed(2);
  }

  static getAchievementsIcons(tier: AchievementTier | 'personalBest'){
    switch (tier) {
      case 'personalBest':
        return 'assets/icons/achievement-personal-best.svg';

      case AchievementTier.S:
        return 'assets/icons/achievement-s.svg';

      case AchievementTier.A:
        return 'assets/icons/achievement-a.svg';

      case AchievementTier.B:
        return 'assets/icons/achievement-b.svg';

      case AchievementTier.C:
        return 'assets/icons/achievement-c.svg';
    }
  }

  static getFormattedMapName(mapName: string): string {
    const segments = ViewHelper.getFormattedMapNameWithPrefix(mapName).split('_', 2);
    return (segments[segments.length - 1]).charAt(0).toUpperCase() + (segments[segments.length - 1]).slice(1);
  }

  public static formatCsRating(premierRating: number): string {
    return new Intl.NumberFormat('en-US').format(premierRating);
  }

  public static getRankLabel(dataSource: DataSource, skillLevel: number, rankType: MatchmakingRankType | null): string {
    switch (dataSource) {
      case DataSource.FACEIT: {
        if (skillLevel < 1 || skillLevel > 10 || !Number.isInteger(skillLevel)) return;
        return `Faceit Level ${skillLevel}`;
      }

      case DataSource.MATCHMAKING:
      case DataSource.MATCHMAKING_WINGMAN: {
        if (rankType === MatchmakingRankType.CS2_COMPETITIVE) {
          return new Intl.NumberFormat('en-US').format(skillLevel);
        }

        switch (skillLevel) {
          case 0: return 'Unranked/Expired';
          case 1: return 'Silver 1';
          case 2: return 'Silver 2';
          case 3: return 'Silver 3';
          case 4: return 'Silver 4';
          case 5: return 'Silver Elite';
          case 6: return 'Silver Elite Master';
          case 7: return 'Gold Nova 1';
          case 8: return 'Gold Nova 2';
          case 9: return 'Gold Nova 3';
          case 10: return 'Gold Nova Master';
          case 11: return 'Master Guardian 1';
          case 12: return 'Master Guardian 2';
          case 13: return 'Master Guardian Elite';
          case 14: return 'Distinguished Master Guardian';
          case 15: return 'Legendary Eagle';
          case 16: return 'Legendary Eagle Master';
          case 17: return 'Supreme Master First Class';
          case 18: return 'The Global Elite';
        }
        // eslint-ignore-line no-fallthrough
      }

      default: return `${dataSource} ${skillLevel}`;
    }
  }

  public static onPlayerHeaderErrored(e: ErrorEvent): void {
		ViewHelper.onImageErrored(e, ViewHelper.PLAYER_HEADER_FALLBACK);
	}

	public static onProfilePictureErrored(e: ErrorEvent): void {
		ViewHelper.onImageErrored(e, ViewHelper.PROFILE_PICTURE_FALLBACK);
	}

	public static onImageErrored(e: ErrorEvent, fallback: string): void {
		if (e.target && e.target instanceof HTMLImageElement && e.target.getAttribute('src') !== fallback) {
			e.target.setAttribute('src', fallback);
		}
	}

  public static scrollToHashAnchor(): void {
		if (typeof window === 'undefined') return;
		if (!window.location.hash) return;

		const el = document.querySelector(window.location.hash);
		if (el) el.scrollIntoView({ behavior: 'smooth' });
	}
}
