import { Component, Input } from '@angular/core';

// icons
export enum ExtendedIcon {
	CHECK_CIRCLE = 'check_circle',
	LIFE_RING = 'lifeRing',
	LOGOUT = 'logout',
	NOTIFICATION_BELL = 'notification_bell',
	RIOT = 'riot',
	ROBOT = 'robot',
	SATELLITE = 'satellite',
	V_SHAPED_ARROW_LEFT = 'v_shaped_arrow_left',
	V_SHAPED_ARROW_RIGHT = 'v_shaped_arrow_right',
}

/**
 * Use `font-size` to change the icon's size.
 *
 * NOTE: Not all icons use a square aspect ratio.
 */
@Component({
	selector: 'extended-icon',
	templateUrl: './icon.component.html',
	styleUrls: ['./icon.component.scss'],
})
export class ExtendedIconComponent {
	@Input() public icon: ExtendedIcon;
	@Input() public withFill: boolean = false;
	@Input() public fillColor: string = 'currentColor';
	@Input() public strokeColor: string = 'currentColor';

	protected readonly Icon = ExtendedIcon;
}
