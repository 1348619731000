<img
	*ngIf="!isPremierRating"
	[alt]="label"
	[ngbTooltip]="showTooltip ? label : null"
	[src]="iconUrl"
	class="rank-icon"
	container="body"
	openDelay="300"
	tooltipClass="default-tooltip"
>

<app-cs-rating
	*ngIf="isPremierRating"
	[rating]="skillLevel"
	[tooltipOverride]="showTooltip ? null : ''"
></app-cs-rating>
