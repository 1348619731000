import { NgbNavModule, NgbProgressbarModule, NgbToast, NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { NgModule } from '@angular/core';
import { NgSelectModule } from '@ng-select/ng-select';
import { TitleCasePipe } from '@angular/common';

import { AtomsModule } from 'src/components/atoms/atoms.module';

import { AppLayoutComponent } from 'src/app/layouts/app-layout/app-layout.component';
import { HighlightIframeUrlPipe } from './pipes/highlight-iframe-url.pipe';
import { NavModule } from 'src/components/common/nav/nav.module';
import { AttemptingLoginComponent } from './layout/attempting-login/attempting-login.component';
import { RankIconComponent } from './widgets/rank-icon/rank-icon.component';
import { CsRatingComponent } from './widgets/rank-icon/cs-rating.component';
import { OnboardingLayoutComponent } from './layouts/onboarding-layout/onboarding-layout.component';
import { ToastComponent } from 'src/app/toast/toast.component';
import { LottieModule } from './lottie.module';
import { ExtendedIconComponent } from './widgets/icon-extension/icon.component';
import { SocialNotificationWidget } from './widgets/social-notification-widget/social-notification.component';
import { CommonDiscordConnectButtonComponent } from 'src/components/common/discord-connect-button/discord-connect-button.component';
const components = [
  AttemptingLoginComponent,
  AppLayoutComponent,
  CsRatingComponent,
  HighlightIframeUrlPipe,
  RankIconComponent,
  SocialNotificationWidget,
  OnboardingLayoutComponent,
  ToastComponent,
  ExtendedIconComponent,
  CommonDiscordConnectButtonComponent
];

@NgModule({
  declarations: [...components],

  imports: [
    AtomsModule,
    NgbNavModule,
    NgbProgressbarModule,
    NgSelectModule,
    LottieModule,
    NgbTooltip,
    NgbToast,
    NavModule,
  ],

  exports: [AtomsModule, NgbNavModule, NgbProgressbarModule, NgSelectModule, LottieModule, NavModule, ...components],

  providers: [TitleCasePipe],
})
export class SharedModule {
  public constructor() {}
}
