<div
	class="cs-rating --tier-{{ ratingTier }}"
	[ngbTooltip]="tooltip"
	container="body"
	openDelay="300"
	tooltipClass="default-tooltip"
>
	<svg class="vertical-lines" viewBox="0 0 17 32">
		<path d="M5.44 2.13A2.6 2.6 0 0 1 7.99 0h1.86a.6.6 0 0 1 .6.7L4.83 31.5a.6.6 0 0 1-.6.5h-2.3c-1 0-1.76-.9-1.58-1.89l5.1-27.98ZM11.82.99c.1-.57.6-.99 1.18-.99h2.93a.6.6 0 0 1 .59.7l-5.4 30.31c-.1.57-.6.99-1.18.99H7a.6.6 0 0 1-.59-.7L11.82.98Z" />
	</svg>

	<div *ngIf="showLabel" class="label-outer">
		<div class="label-wrapper">
			<span *ngIf="labelOverride" class="label-large">
				{{ labelOverride }}
			</span>

			<span *ngIf="!labelOverride && isUnranked" class="label-large">
				---
			</span>

			<ng-container *ngIf="!labelOverride && !isUnranked">
				<span class="label-large">{{ labelLarge }},</span>
				<span class="label-small">{{ labelSmall | number:'3.0'}}</span>
			</ng-container>
		</div>
	</div>

	<div *ngIf="!showLabel" class="label-spacer"></div>
</div>
