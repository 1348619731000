import { Component, Input, OnChanges } from '@angular/core';
import { ViewHelper } from '../../helpers/view.helper';

@Component({
	selector: 'app-cs-rating',
	templateUrl: './cs-rating.component.html',
	styleUrls: ['./cs-rating.component.scss'],
})
export class CsRatingComponent extends Component implements OnChanges {
	@Input() protected readonly labelOverride: string = null;
	@Input() protected readonly tooltipOverride: string = null;
	@Input() protected readonly rating: number;
	@Input() protected readonly showLabel = true;

	protected isUnranked: boolean;
	protected labelLarge: string;
	protected labelSmall: string;
	protected ratingTier: number;
	protected sanitizedRating: number;
	protected tooltip: string;

	public ngOnChanges(): void {
		const rating = this.sanitizedRating = this.rating || 0;

		this.ratingTier = Math.min(6, Math.floor(rating / 5000));
		this.isUnranked = this.sanitizedRating < 1000;

		this.tooltip = this.tooltipOverride
			?? this.labelOverride
			?? (this.isUnranked ? '---' : ViewHelper.formatCsRating(this.sanitizedRating));

		if (!this.labelOverride) {
			this.labelLarge = Math.floor(rating / 1000).toString();
			this.labelSmall = (rating % 1000).toString();
		}
	}
}
