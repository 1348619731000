import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MetaService } from '../../../services/meta.service';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-error-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss'],
  standalone: true
})
export class ErrorNotFoundComponent implements OnDestroy, OnInit {
	public constructor(
		protected readonly metaService: MetaService,
		protected readonly router: Router,
	) {
		//
	}

  public goToSupport() {
    window.location.href = `${environment.frontendBaseUrl}/app/support`;
  }

	public ngOnInit(): void {
		this.metaService.setTitle('Not found :(');
	}

	public ngOnDestroy(): void {
		this.metaService.reset();
	}
}
