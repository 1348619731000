export interface CsFriendSuggestionDTO {
	id: string;
	steamNickname: string;
	steamAvatarUrl?: string;
}

export enum MatchResult {
    WIN = 'win',
    LOSS = 'loss',
    TIE = 'tie',
}

export enum GameName {
    LEAGUE = 'league',
    CS = 'cs',
    DEADLOCK = 'deadlock',
}

export interface HomepageDTO {
    gameName: GameName;
    matchId: string;
    matchDetails: LoLMatchDetailsDTO | CsMatchDetailsDTO | DeadlockMatchDetailsDTO;
    teams: TeamDTO[];
    matchFinishedAt: string;
}

export interface TeamDTO {
    teamId: string | number;
    participants: ParticipantDTO[]; // only user + participants queued with user
    matchResult: MatchResult;
    ownScore?: number;
    enemyScore?: number;
}

export interface LoLMatchDetailsDTO {
    queueId: number;
    matchDuration: number;
}

export interface CsMatchDetailsDTO {
    dataSource: string;
    rankType?: number;
    mapName: string;
}

export interface DeadlockMatchDetailsDTO {
    matchDuration: number;
}

export interface ParticipantDTO {
    leetifyUserId: string;
    leetifyRating?: number;
    username: string;
    avatar: string;
    achievements?: AchievementDTO[];
    highlights?: HighlightDTO[];
    stats: LolStatsDTO | CsStatsDTO | DeadlockStatsDTO;
}

export interface AchievementDTO {
    title: string;
    description: string;
    tier: string | number;
    priority: number;
}

export interface HighlightDTO {
    description?: string;
    roundNumber: number;
    thumbnailUrl?: string;
    url: string;
    status: string;
}

export interface StatsDTO {
    kills: number;
    assists: number;
    deaths: number;
    rank?: string | number;
}

export interface LolStatsDTO extends StatsDTO {
    role: string;
    userTag: string;
    championId: string;
    championName: string;
    totalDmg: number;
    lpDifference: number;
}

export interface CsStatsDTO extends StatsDTO {
    //
}

export interface DeadlockStatsDTO extends StatsDTO {
    heroName: string;
    totalSouls: number;
}
