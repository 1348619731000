import { Component, Input, OnInit } from '@angular/core';
import { AchievementDTO, HomepageDTO, TeamDTO } from '../../../../../../leetify-home-dtos/homepage-dto';
import { animate, group, state, style, transition, trigger } from '@angular/animations';
import { ViewHelper } from '../../../../helpers/view.helper';
import { AchievementTier } from '../../../../../../leetify-shared-utils/enums';
import { StandoutService } from '../../../../services/standout.service';

@Component({
  selector: 'standout',
  templateUrl: 'standout.component.html',
  styleUrls: ['standout.component.scss'],
  animations: [
    trigger('slideInOut', [
      state(
        'in',
        style({
          'max-height': '500px',
          opacity: '1',
          visibility: 'visible',
        })
      ),
      state(
        'out',
        style({
          'max-height': '0px',
          opacity: '0',
          visibility: 'hidden',
        })
      ),
      transition('in => out', [
        group([
          animate(
            '400ms ease-in-out',
            style({
              opacity: '0',
            })
          ),
          animate(
            '600ms ease-in-out',
            style({
              'max-height': '0px',
            })
          ),
          animate(
            '700ms ease-in-out',
            style({
              visibility: 'hidden',
            })
          ),
        ]),
      ]),
      transition('out => in', [
        group([
          animate(
            '1ms ease-in-out',
            style({
              visibility: 'visible',
            })
          ),
          animate(
            '600ms ease-in-out',
            style({
              'max-height': '500px',
            })
          ),
          animate(
            '800ms ease-in-out',
            style({
              opacity: '1',
            })
          ),
        ]),
      ]),
    ]),
  ],
})
export class StandoutComponent implements OnInit {
  @Input() post: HomepageDTO;
  @Input() team: TeamDTO;
  @Input() activeTab: number;

  protected standoutPerTab: {
    achievement: AchievementDTO | void;
    tier: string | number;
    title: string;
    description: string;
    class: string;
  }[] = [];

  constructor(protected readonly standoutService: StandoutService) {}

  protected getAchievementTitleAndDescription(achievement: AchievementDTO | void, tab: number): { title: string; description: string } {
    if (achievement) {
      const username = this.team.participants[tab].username;

      const pronounMap: { [key: string]: string } = {
        yours: 'theirs',
        your: 'their',
        "you're": `${username} is`,
        "you've": `${username} has`,
        you: username,
      };

      let title: string;
      let description: string;

      if (tab !== 0) {
        const regex = new RegExp(`(${Object.keys(pronounMap).join('|')})`, 'gi');

        title = achievement.title.replace(regex, (match) => {
          const replacement = pronounMap[match.toLowerCase()];
          return match[0] === match[0].toUpperCase() ? replacement.charAt(0).toUpperCase() + replacement.slice(1) : replacement;
        });

        description = achievement.description.replace(regex, (match) => {
          const replacement = pronounMap[match.toLowerCase()];
          return match[0] === match[0].toUpperCase() ? replacement.charAt(0).toUpperCase() + replacement.slice(1) : replacement;
        });
      } else {
        title = achievement.title;
        description = achievement.description;
      }

      return { title, description };
    }
  }

  protected getAchievementIcon(achievement) {
    if (achievement) return ViewHelper.getAchievementsIcons(achievement.tier as AchievementTier | 'personalBest');
  }

  protected setAchievementClass(className: string, achievement: AchievementDTO | void) {
    if (achievement) {
      switch (achievement.tier as AchievementTier | 'personalBest') {
        case 'personalBest':
          className += ' personal-best';
          break;
        case AchievementTier.S:
          className += ' s-tier';
          break;
        case AchievementTier.A:
          className += ' a-tier';
          break;
        case AchievementTier.B:
          className += ' b-tier';
          break;
        case AchievementTier.C:
          className += ' c-tier';
          break;
      }
    }
    return className;
  }

  public ngOnInit(): void {
    this.team.participants.forEach((participant, index) => {
      const standoutAchievement = this.standoutService.getStandoutAchievement(participant.achievements);
      const titleAndDescription = this.getAchievementTitleAndDescription(standoutAchievement, index);
      this.standoutPerTab.push({
        achievement: standoutAchievement,
        tier: standoutAchievement ? standoutAchievement.tier : 0,
        title: titleAndDescription ? titleAndDescription.title : '',
        description: titleAndDescription ? titleAndDescription.description : '',
        class: this.setAchievementClass('standout-panel', standoutAchievement),
      });
    });
  }
}
