<div *ngIf="isDiscordConnected === undefined">
	<img class="spinner" src="assets/images/spinner.svg" alt="Loading...">
</div>

<div *ngIf="isDiscordConnected">
	<div class="connected-badge">
		<i class="mdi mdi-check"></i>
		Discord account connected to Leetify
	</div>

	<div class="discord-connect-controls">
		<button class="disconnect-button" (click)="disconnectDiscord()">
			Disconnect Discord
		</button>
	</div>
</div>

<div *ngIf="isDiscordConnected === false">
	<p>To get your Pro role on Discord, you need to connect your account</p>

	<div class="discord-connect-controls">
		<a [href]="discordLoginUrl" class="btn btn-lg btn-primary">
			<span class="connect-button-logos">
				<i class="leet-leetify icon-size"></i>
				<span class="mr-1 ml-1">+</span>
				<i class="fab fa-discord mr-2"></i>
			</span>

			<span>Connect Discord</span>
		</a>
	</div>
</div>
