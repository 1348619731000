<section id="change-password" class="card">
	<div class="card-header">
		<i class="mdi mdi-form-textbox-password"></i>
		<h2>Change Password</h2>
	</div>

	<div *ngIf="updateDetailsCheckRequestInProgress" class="card-body --loading">
		<img class="spinner" src="/assets/images/spinner.svg" alt="Loading...">
	</div>

	<form *ngIf="!updateDetailsCheckRequestInProgress && hasPasswordSet" #passwordForm="ngForm" class="card-body" (submit)="submit(passwordForm, 'passwordFormErrors')">
		<label class="form-group">
			Current Password
			<input name="currentPassword" type="password" class="form-control" autocomplete="current-password" ngModel required>
			<div *ngIf="passwordFormErrors.currentPassword" class="form-text text-danger">{{ passwordFormErrors.currentPassword }}</div>
		</label>

		<label class="form-group">
			New Password
			<input name="newPassword" type="password" class="form-control" autocomplete="new-password" ngModel>
			<div *ngIf="passwordFormErrors.newPassword" class="form-text text-danger">{{ passwordFormErrors.newPassword }}</div>
		</label>

		<div class="form-group submit-wrapper mb-0">
			<button type="submit" class="btn btn-primary" [disabled]="requestInProgress">Change Password</button>
		</div>
	</form>

	<div *ngIf="!updateDetailsCheckRequestInProgress && !hasPasswordSet" class="card-body">
		<p>
			You don't currently have a password set.
			<br>
			To set a password, please request a password reset link.
		</p>

		<!-- <app-auth [inline]="true" forceView="reset-password"></app-auth> -->

		<p class="mb-0">
			If you need help, <a routerLink="/app/support">let us know.</a>
		</p>
	</div>
</section>

<section id="change-email" class="card">
	<div class="card-header">
		<i class="mdi mdi-email"></i>
		<h2>Change Email Address</h2>
	</div>

	<div *ngIf="updateDetailsCheckRequestInProgress" class="card-body --loading">
		<img class="spinner" src="/assets/images/spinner.svg" alt="Loading...">
	</div>

	<form *ngIf="!updateDetailsCheckRequestInProgress && hasPasswordSet" #emailForm="ngForm" class="card-body" (submit)="submit(emailForm, 'emailFormErrors')">
		<label class="form-group">
			Current Email Address
			<input type="text" class="form-control" [value]="user.email" readonly disabled>
		</label>

		<label class="form-group">
			Current Password
			<input name="currentPassword" type="password" class="form-control" autocomplete="current-password" ngModel required>
			<div *ngIf="emailFormErrors.currentPassword" class="form-text text-danger">{{ emailFormErrors.currentPassword }}</div>
		</label>

		<label class="form-group">
			New Email Address
			<input name="newEmail" type="email" class="form-control" autocomplete="email" ngModel>
			<div *ngIf="emailFormErrors.newEmail" class="form-text text-danger">{{ emailFormErrors.newEmail }}</div>
		</label>

		<div class="form-group submit-wrapper mb-0">
			<button type="submit" class="btn btn-primary" [disabled]="requestInProgress">Change Email Address</button>
		</div>
	</form>

	<div *ngIf="!updateDetailsCheckRequestInProgress && !hasPasswordSet" class="card-body">
		<p>
			To change your email address, please set a password first.
		</p>

		If you need help, <a routerLink="/app/support">let us know.</a>
	</div>
</section>
