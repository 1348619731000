import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { PostService } from '../../../services/post.service';
import { HomepageDTO, TeamDTO } from '../../../../../leetify-home-dtos/homepage-dto';
import { Icon } from 'src/components/atoms/icon/icon.component';
import { takeUntil } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { User } from '../../../models/user.model';
import { UserService } from '../../../services/user.service';
import { Post } from '../../../models/model';
import { Description } from '../../../models/description.model';

@Component({
  selector: 'shared-post-component-layout',
  templateUrl: './shared-post.component.html',
  styleUrls: ['./feed.component.scss'],
})

export class SharedPostComponent implements OnDestroy, OnInit {
  protected readonly Icon = Icon;
  protected readonly ngUnsubscribe = new Subject<void>();
  protected post: HomepageDTO = null;
  protected teams: TeamDTO[] = [];
  protected backgroundImage = 'assets/images/join-background.png';
  protected user: User;
  protected postDescriptions: Description[] = [];

  public constructor(
    protected readonly router: Router,
    private postService: PostService,
    private userService: UserService,
    private route: ActivatedRoute,
  ) {}

  protected findDescriptionsForMatchId(matchId: string) {
    return this.postDescriptions.filter(description => description.matchId === matchId);
  }

  protected async loadDescriptionsForPost(posts: HomepageDTO[]) {
    const postsToRequest: Post[] = posts.map(({gameName, matchId}) => ({
      gameName,
      matchId
    }));

    const resp = await this.postService.reloadPostDescription(postsToRequest).then(
      (data) => {
        return data;
      });

    // reset postDescriptions
    this.postDescriptions = [];

    for (const r of resp) {
      this.postDescriptions.push({
        matchId: r.matchId,
        leetifyUserId: r.leetifyUserId,
        description: r.description,
      });
    }
  }

  private handlePost(post: HomepageDTO): void {
    this.post = post;
    this.teams = this.post.teams;

    this.loadDescriptionsForPost([this.post]).then();
  }

  protected loadPost() {
    this.postService.singlePost$
      .pipe(takeUntil(this.ngUnsubscribe),).subscribe((singlePost) => this.handlePost(singlePost.response));
  }

  protected goToLogin() {
    window.location.href = `${environment.frontendBaseUrl}/auth/login`;
  }

  protected goToSignup() {
    window.location.href = `${environment.frontendBaseUrl}/auth/signup`;
  }

  protected setUser(user: User): void {
    if (user) {
      this.user = user;
    }
  }

  async ngOnInit() {
    this.userService.user$.pipe(takeUntil(this.ngUnsubscribe)).subscribe( (user) =>  this.setUser(user));
    this.setUser(this.userService.user);

    this.route.params.subscribe((params) => {
      if (!params.game || !params.matchId || !params.userId) return;

      this.loadPost();
      this.postService.reloadSinglePost(params.game, params.matchId, params.userId);
    });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
