@if (!isNewUser) {
  <div class="nav-wrapper">
    <a [routerLink]="'/'" class="sidebar-logo">
      <img src="assets/images/leetify-logo-primary-white.svg" alt="Leetify" />
    </a>

    <leetify-common-topnav [navigationActive]="navigationActive" (toggleNavigation)="toggleNavigation($event)"></leetify-common-topnav>
  </div>
}
<main class="container-fluid">
  <img class="platform-logo" src="assets/images/leetify-logo-primary-white.png" alt="Leetify" />
  <div class="row">
    <p class="main text">{{ mainText }}</p>
  </div>
  <div class="row" style="max-width: 40%">
    <p class="secondary text">{{ secondaryText }}</p>
  </div>
  @if(currentState === 'noEmail') {
    <div class="row center no-email-container">
      <div class="col-12 col-md-6">
        <h1 class="mb-2">What's your Email Address?</h1>
        <p class="mb-4">In case you ever need to reset your password or contact Support</p>
    
        <form (submit)="submitEmailAddress()">
          <input type="email" name="email" class="form-control" [(ngModel)]="emailAddress" required autofocus>
          <div class="invalid-feedback d-block" *ngIf="error">{{ error }}</div>
    
          <button class="btn btn-primary btn-lg px-2 px-lg-4 mt-4" type="submit" [disabled]="!emailAddress || requestInProgress">
            Next
          </button>
        </form>
      </div>
    </div>
  }

  <div class="row image-grid" [hidden]="currentState !== 'gameSelection'">
    @for (game of games; track game.name) {
      <div
        class="game-image-container"
        (click)="selectGame(game)"
        [style]="game.crossFadeCss"
        (mouseenter)="onHover(game, true)"
        (mouseleave)="onHover(game, false)"
      ></div>
    }
  </div>

  <!-- LoL related stages -->
  <div class="row image-grid" style="margin-top: 1rem" [hidden]="currentState !== 'riot'">
    <button class="button invisible-button" (click)="setState(OnboardingStates.GAME_SELECTION)">
      <extended-icon [icon]="ExtendedIcon.V_SHAPED_ARROW_LEFT" style="margin-right: 0.5rem; width: 30px"></extended-icon>
      Back
    </button>
    <button class="button primary-button" (click)="onRiotClick()">
      <extended-icon [icon]="ExtendedIcon.RIOT" style="margin-right: 0.5rem" withFill="true" fillColor="fill: #FFF"></extended-icon>
      Connect Riot account
    </button>
  </div>

  <div class="row" style="display: flex; flex-direction: column; align-items: center" [hidden]="currentState !== 'discord'">
    <div style="display: flex; margin-top: 1rem">
      <button class="button invisible-button" (click)="setState(OnboardingStates.RIOT)">
        <extended-icon [icon]="ExtendedIcon.V_SHAPED_ARROW_LEFT" style="margin-right: 0.5rem; width: 30px"></extended-icon>
        Back
      </button>
      <button class="button primary-button" (click)="onDiscordClick()">
        <extended-icon [icon]="ExtendedIcon.ROBOT" style="margin-right: 0.5rem"></extended-icon>
        Add our bot
      </button>
    </div>
    <div>
      <button class="button invisible-button-pink" (click)="onSkipDiscordClick()">Skip this for now</button>
    </div>
  </div>

  <!-- CS related stages -->
  <div class="row" style="margin-top: 1rem" [hidden]="currentState !== 'steam'">
    <button class="button invisible-button" (click)="setState(OnboardingStates.GAME_SELECTION)">
      <extended-icon [icon]="ExtendedIcon.V_SHAPED_ARROW_LEFT" style="margin-right: 0.5rem; width: 30px"></extended-icon>
      Back
    </button>
    <button class="button primary-button" (click)="onSteamClick()">
      <leetify-icon [icon]="Icon.STEAM" style="margin-right: 0.5rem" withFill="true" fillColor="fill: #FFF"></leetify-icon>
      Connect Steam
    </button>
  </div>

  <div class="row data-source-grid" [hidden]="currentState !== 'dataSource'">
    <button class="button data-source-button" (click)="onMMClick()">
      <leetify-icon [icon]="Icon.STEAM" style="margin-left: 1rem" withFill="true" fillColor="fill: #FFF"></leetify-icon>
      Connect matchmaking
      <extended-icon [icon]="ExtendedIcon.V_SHAPED_ARROW_RIGHT" style="margin-right: 0.5rem"></extended-icon>
    </button>
    <button class="button data-source-button" (click)="onFaceitClick()">
      <leetify-icon [icon]="Icon.FACEIT" style="margin-left: 1rem" withFill="true" fillColor="fill: #FFF"></leetify-icon>
      Connect faceit
      <extended-icon [icon]="ExtendedIcon.V_SHAPED_ARROW_RIGHT" style="margin-right: 0.5rem"></extended-icon>
    </button>
    <button class="button invisible-button" (click)="setState(OnboardingStates.STEAM)">
      <extended-icon [icon]="ExtendedIcon.V_SHAPED_ARROW_LEFT" style="margin-right: 0.5rem; width: 30px"></extended-icon>
      Back to Steam account
    </button>
  </div>


  @if(currentState === 'matchmaking') {
    <div class="row" style="max-width: 35%">
      <div class="secondary text" style="text-align: start">
        <p>
          1. Login with your steam account
          <a target="_blank" href="https://help.steampowered.com/en/wizard/HelpWithGameIssue/?appid=730&issueid=128">here</a>.
        </p>
        <p>2. Scroll down to access "Your Match History".</p>
        <p>
          3. Copy "Your most recently completed match token" and the "authentication code". If it's not there yet, click "Create Authentication Code" to
          generate one.
        </p>
      </div>
      <div class="row mb-3" style="width: 106%">
        <div class="col-12">
          <div class="form-group mb-0">
            <label class="col-form-label" for="lastShareCodeValid" *ngIf="isLastShareCodeValid">Match token</label>
            <label class="col-form-label text-danger" for="lastShareCodeValid" *ngIf="!isLastShareCodeValid">
              Match token (*Please provide the correct one)
            </label>
            <input
              class="form-control mb-2"
              id="steamLastShareCode"
              type="text"
              placeholder="CSGO-Zffxx-Wxxxx-Wxxxx-Txxxx-xxxxx"
              [(ngModel)]="steamLastShareCode"
              (ngModelChange)="handleShareCodeInput(steamLastShareCode)"
              [class]="{ 'is-invalid': !isLastShareCodeValid }"
            />
  
            <label class="col-form-label" for="steamAuthCode" *ngIf="isAuthCodeValid">Authentication code</label>
            <label class="col-form-label text-danger" for="steamAuthCode" *ngIf="!isAuthCodeValid">
              Authentication code (*Please provide the correct one)
            </label>
  
            <input
              class="form-control mb-2"
              id="steamAuthCode"
              type="text"
              placeholder="AAAA-AAAAA-AAAA"
              [(ngModel)]="steamAuthCode"
              (ngModelChange)="handleAuthCodeInput(steamAuthCode)"
              [class]="{ 'is-invalid': !isAuthCodeValid }"
            />
          </div>
        </div>
      </div>
      <div class="row" style="width: 106%; justify-content: center">
        <button class="button invisible-button" (click)="setState(OnboardingStates.STEAM)">
          <extended-icon [icon]="ExtendedIcon.V_SHAPED_ARROW_LEFT" style="margin-right: 0.5rem; width: 30px"></extended-icon>
          Back
        </button>
        <button class="button primary-button" (click)="connectMatchMaking()" [disabled]="!isAuthCodeValid || !isLastShareCodeValid">
          <leetify-icon [icon]="Icon.STEAM" style="margin-right: 0.5rem" withFill="true" fillColor="fill: #FFF"></leetify-icon>
          Connect Steam
        </button>
      </div>
      <div class="row">
        <ng-container *ngIf="didSteamRejectShareCode">
          <ol class="mb-3 pl-3 text-danger">
            <li>Make sure your share code is from a match within the past 30 days.</li>
            <li>Check for typos in the share code you provided.</li>
          </ol>
  
          <p>
            If you're 100% sure your share code is typed correctly, it means your share code is expired. You'll need to play a new matchmaking game to
            acquire an active share code and reenter it here.
          </p>
          <p>
            <span style="color: #4675ea">Note:</span> You can log into Leetify now by connecting another data source or by updating your share code
            above after playing another match.
          </p>
        </ng-container>
      </div>
    </div>
  }


  <div class="row image-grid" [hidden]="currentState !== 'steamBot'">
    <button class="button invisible-button" (click)="setState(OnboardingStates.DATA_SOURCE)">
      <extended-icon [icon]="ExtendedIcon.V_SHAPED_ARROW_LEFT" style="margin-right: 0.5rem; width: 30px"></extended-icon>
      Back
    </button>
    <button class="button primary-button" (click)="onSteamBotClick()">
      <extended-icon [icon]="ExtendedIcon.ROBOT" style="margin-right: 0.5rem"></extended-icon>
      Add our bot
    </button>
  </div>

  <!--bottom row-->
  <div class="footer row align-items-center justify-content-end">
    <div style="width: 12%"></div>
    <div style="width: 76%" [hidden]="currentState === 'gameSelection'">
      <p class="text tertiary">{{ footerText }}</p>
      <div class="steps">
        @for (step of selectedSteps?.steps; track step; let last = $last) {
          <div class="step">
            <extended-icon
              [icon]="step.icon"
              [strokeColor]="step.color"
              [withFill]="step.filled"
              [fillColor]="step.fillColor"
              withFill="false"
            ></extended-icon>
            <p class="text tertiary" [style]="{ color: step.color }">{{ step.text }}</p>
          </div>
          @if (!last) {
            <div class="divider"></div>
          }
        }
      </div>
    </div>
    <div style="display: flex; justify-content: space-around; flex-direction: column; width: 12%">
      <button (click)="onSupport()" class="button secondary-button" style="gap: 2px">
        <extended-icon style="height: 15px" fillColor="fill: transparent" withFill="true" [icon]="ExtendedIcon.LIFE_RING"></extended-icon>
        Support
      </button>
      <button (click)="onLogout()" class="button secondary-button" style="gap: 5px">
        <extended-icon style="height: 15px" fillColor="fill: transparent" withFill="true" [icon]="ExtendedIcon.LOGOUT"></extended-icon>
        Logout
      </button>
    </div>
  </div>
  @if (!isNewUser) {
    <div class="mobile-nav-darken-overlay" [ngClass]="{ '--active': navigationActive }" (click)="onMainContentClick($event)"></div>
  }
</main>
