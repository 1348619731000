export const environment = {
  production: false,
  dateFormat: 'YYYY-MM-DD',
  apiUrl: 'https://home-api.i-staging.leetify.com',
  // For now duplicating this, pls forgive
  homeApiUrl: 'https://home-api.i-staging.leetify.com',
  csApiUrl: 'https://api.cs-staging.leetify.com',
  lolApiUrl: 'https://api.lol-staging.leetify.com',
  frontendBaseUrl: 'https://ezmid.leetify.com',
  // For now duplicating this, pls forgive, this should just be frontendBaseUrl
  // TODO: SHARED-TOP-NAV Fix before launch
  csFrontendBaseUrl: 'https://ezmid.leetify.com',
};
