import { Component, Input } from '@angular/core';
import { ReceiptDTO } from 'leetify-shared-utils/dto';

@Component({
	selector: 'app-receipts',
	templateUrl: './receipts.component.html',
})
export class ReceiptsComponent {
	@Input() public receipts: ReceiptDTO[] = [];
}
