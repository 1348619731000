import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import {
  AchievementDTO,
  GameName,
  HighlightDTO,
  HomepageDTO,
  TeamDTO
} from '../../../../../../leetify-home-dtos/homepage-dto';
import { animate, group, state, style, transition, trigger } from '@angular/animations';
import { ViewHelper } from '../../../../helpers/view.helper';
import { AchievementTier } from '../../../../../../leetify-shared-utils/enums';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'stats-card',
  templateUrl: 'stats-card.component.html',
  styleUrls: ['stats-card.component.scss'],
  animations: [
    trigger('slideInOut', [
      state('in', style({
        'max-height': '500px', 'opacity': '1', 'visibility': 'visible'
      })),
      state('out', style({
        'max-height': '0px', 'opacity': '0', 'visibility': 'hidden'
      })),
      transition('in => out', [group([
          animate('400ms ease-in-out', style({
            'opacity': '0'
          })),
          animate('600ms ease-in-out', style({
            'max-height': '0px'
          })),
          animate('700ms ease-in-out', style({
            'visibility': 'hidden'
          }))
        ]
      )]),
      transition('out => in', [group([
          animate('1ms ease-in-out', style({
            'visibility': 'visible'
          })),
          animate('600ms ease-in-out', style({
            'max-height': '500px'
          })),
          animate('800ms ease-in-out', style({
            'opacity': '1'
          }))
        ]
      )])
    ]),
  ]
})

export class StatsCardComponent implements OnInit{
  @Input() post: HomepageDTO;
  @Input() team: TeamDTO;
  @Input() activeTab: number;
  @ViewChild('splash-container') splashContainerRef: ElementRef;
  @Input() achievements: AchievementDTO[];
  @Input() highlight: HighlightDTO[];
  @Input() cardTitle: string;
  @Input() cardSubtitle: string;
  @Input() kills: number;
  @Input() assists: number;
  @Input() deaths: number;
  @Input() splashImage: string;
  @Input() splashStyle: string;
  @Input() gameLength: string;
  @Input() mainGameStat: string;
  @Input() icon: string;

  protected isVideoVisible: boolean = false;

  protected isLeague() {
    return this.post.gameName === GameName.LEAGUE;
  }

  protected isDeadlock() {
    return this.post.gameName === GameName.DEADLOCK;
  }

  protected isCs() {
    return this.post.gameName === GameName.CS;
  }

  protected isWin() {
    return this.team.ownScore > this.team.enemyScore;
  }

  protected isNegativeRating() {
    return this.team.participants[this.activeTab].leetifyRating < 0;
  }

  protected showVideo(event: Event) {
    event.stopPropagation();
    this.isVideoVisible = !this.isVideoVisible;
    return this.isVideoVisible;
  }

  protected switchTooltips(tooltipToOpen: NgbTooltip, tooltipToClose: NgbTooltip) {
    if (tooltipToOpen) {
      tooltipToOpen.open();
    }
    if (tooltipToClose) {
      tooltipToClose.close();
    }
  }

  protected goToMatchList() {
    if (this.post.gameName === GameName.LEAGUE) {
      window.location.href = `${environment.frontendBaseUrl}/lol/match-details/${this.post.matchId}`;
    }
    if (this.post.gameName === GameName.CS){
      window.location.href = `${environment.frontendBaseUrl}/app/match-details/${this.post.matchId}`;
    }
  }

  protected getAchievementTitleAndDescription(index: number) {
    const achievement = this.achievements[index];
    const username = this.team.participants[this.activeTab].username;

    const pronounMap: { [key: string]: string } = {
      "yours": 'theirs',
      "your": 'their',
      "you're": `${username} is`,
      "you've": `${username} has`,
      "you": username,
    };

    if (this.activeTab !== 0) {
      const regex = new RegExp(`(${Object.keys(pronounMap).join('|')})`, 'gi');

      const title = achievement.title.replace(regex, (match) => {
        const replacement = pronounMap[match.toLowerCase()];
        return match[0] === match[0].toUpperCase()
          ? replacement.charAt(0).toUpperCase() + replacement.slice(1)
          : replacement;
      });

      const description = achievement.description.replace(regex, (match) => {
        const replacement = pronounMap[match.toLowerCase()];
        return match[0] === match[0].toUpperCase()
          ? replacement.charAt(0).toUpperCase() + replacement.slice(1)
          : replacement;
      });
      return title + ' ' + description;
    }
    return this.achievements[index].title + ' ' + this.achievements[index].description;
  }

  protected getAchievementIcon(tier: string) {
    return ViewHelper.getAchievementsIcons(tier as AchievementTier | 'personalBest');
  }

  public ngOnInit(): void {
  }
}
