import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

export enum StorageKey {
	CS2_BETA_BANNER_ACKNOWLEDGED = 'cs2_beta_banner_acknowledged',
	DISMISSED_NOTIFICATIONS = 'dismissed_notifications',
	DISMISSED_WARNINGS = 'dismissed_warnings',
	DISMISSED_CLUBS_SUGGESTIONS = 'dismissed_clubs_suggestions',
	DISMISSED_MEMBERS_SUGGESTIONS = 'dismissed_members_suggestions',
	FOUNDERS_EDITION_SUNSET_ACKNOWLEDGED = 'founders_edition_sunset_acknowledged',
	MATCH_DETAILS_OVERVIEW_COLUMNS = 'match_details_overview_columns',
	MATCH_HISTORY_VIEW_MODE = 'match_history_view_mode',
	PUBLIC_PROFILE_ACKNOWLEDGED = 'public_profile_acknowledged',
	RECAP_2022_ACKNOWLEDGED_FOR_STEAM64_ID = 'recap_2022_acknowledged_for_steam64_id',
	RECAP_2023_FILTER_TOOLTIP_ACKNOWLEDGED = 'recap_2023_filter_tooltip_acknowledged',
	REFERRAL_CODE = 'referral_code',
	REFERRAL_EXPIRES_AT = 'referral_expires_at',
	SCOREBOARD_TABLE_ORDER_DIRECTION = 'scoreboard_table_order_direction',
	SCOREBOARD_TABLE_ORDER_KEY = 'scoreboard_table_order_key',
	SCOREBOARD_TABLE_ORDER_MODE = 'scoreboard_table_order_mode',
	VIEWED_HIGHLIGHTS = 'viewed_highlights',
	WINGMAN_BETA_BANNER_ACKNOWLEDGED = 'wingman_beta_banner_acknowledged',
	HOME_ONBOARDING_STATE = 'home_onboarding_state',
}

@Injectable({
	providedIn: 'root',
})
export class StorageService {
	protected readonly isBrowser: boolean;
	protected storage: Storage;

	public constructor(
		@Inject(PLATFORM_ID) platformId: Record<string, any>,
	) {
		this.isBrowser = isPlatformBrowser(platformId);
	}

	public removeItem(key: StorageKey): void {
		if (!this.isBrowser || !this.storage) return null;

		this.storage.removeItem(key);
	}

	public saveValue<T>(key: StorageKey, value: T): void {
		if (!this.isBrowser || !this.storage) return;

		this.storage.setItem(key, JSON.stringify(value));
	}

	public getValue<T>(key: StorageKey): T {
		if (!this.isBrowser || !this.storage) return null;

		const value = this.storage.getItem(key);
		if (!value) return null;

		try {
			return JSON.parse(value) as T;
		} catch {
			return null;
		}
	}
}
