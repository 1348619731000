import { Component, OnInit, OnDestroy } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { NgForm } from '@angular/forms';
import { Subject } from 'rxjs';
import { User } from 'src/app/models/user.model';
import { UserService } from 'src/app/services/user.service';
import { Icon } from 'src/components/atoms/icon/icon.component';
import { AuthService } from 'src/app/auth/auth.service';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ToastService } from 'src/app/toast/toast.service';

@Component({
  selector: 'app-lol-settings',
  templateUrl: './lol-settings-component.html',
  styleUrls: ['./lol-settings-component.scss'],
})
export class LolSettingsComponent implements OnDestroy, OnInit {
  public requestInProgress = false;
  public updateDetailsCheckRequestInProgress = true;
  public user: User;
  public selectedDiscordNotifications: boolean;
  private initialDiscordNotifications: boolean;
  public discordUrl: string = `${environment.csApiUrl}/discord/login?jwt=${AuthService.getToken()}&state=lol`;

  public readonly Icon = Icon;

  protected readonly ngUnsubscribe = new Subject<void>();

  public readonly radioOptions = [
    { value: true, label: 'Enabled', description: 'You will receive a post-match report via Discord from the bot when your match ends.' },
    { value: false, label: 'Disabled', description: 'You will not receive any messages from the bot for LoL related things.' },
  ];

  public constructor(
    protected readonly userService: UserService,
    protected readonly http: HttpClient,
    protected readonly toastService: ToastService
  ) {
    //
  }

  public async updateDiscordNotifications(form: NgForm) {
    if (!form.form.value) return;

    const discordNotifications = form.form.value.lolDiscordNotifications;

    if (discordNotifications === this.initialDiscordNotifications) return;

    try {
      await this.http
        .post(`${environment.lolApiUrl}/api/user/v1/change-discord-notifications`, {}, { headers: AuthService.getHeaders(), responseType: 'text' })
        .toPromise();
      const label = discordNotifications ? 'enabled' : 'disabled';

      this.toastService.success(`Successfully ${label} Discord notifications`);
	  this.initialDiscordNotifications = discordNotifications;
    } catch (e) {
      this.toastService.error('Failed to change Discord notifications settings :(');
    }
  }

  protected handleUser(user: User): void {
    this.user = user;
    this.selectedDiscordNotifications = user.discordNotifications;
    this.initialDiscordNotifications = user.discordNotifications;
  }

  protected connectDiscord(): void {

  }

  public ngOnInit(): void {
    this.userService.user$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((user) => this.handleUser(user));
    this.handleUser(this.userService.user);
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
