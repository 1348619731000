import { HttpClient } from '@angular/common/http';
import { Subject } from 'rxjs';
import { Injectable } from '@angular/core';
import {
  Post,
  PostDescriptionsResponse,
  PostLikesResponse,
  PostResponse,
  SinglePostResponse,
  SuggestedUsersResponse
} from '../models/model';
import { environment } from '../../environments/environment';
import { AuthService } from '../auth/auth.service';

@Injectable({
  providedIn: 'root',
})

export class PostService {

  private readonly newPostsSource = new Subject<PostResponse>();
  public readonly newPosts$ = this.newPostsSource.asObservable();

  private readonly newOnlyMePostsSource = new Subject<PostResponse>();
  public readonly newOnlyMePosts$ = this.newOnlyMePostsSource.asObservable();

  private readonly singlePostSource = new Subject<SinglePostResponse>();
  public readonly singlePost$ = this.singlePostSource.asObservable();

  private readonly suggestedUsersSource = new Subject<SuggestedUsersResponse>();
  public readonly suggestedUsers$ = this.suggestedUsersSource.asObservable();

  constructor(private http: HttpClient) {}

  public reloadPosts(offset: number): void {
    const headers = AuthService.getHeaders();
    this.http.get<PostResponse>(`${environment.apiUrl}/api/feed?offset=${offset}`, { headers }).subscribe(
      (posts: PostResponse) => {
        this.newPostsSource.next(posts);
        return posts;
      },
      (e) => {
        console.log(e);
        return null;
      });
  }

  public reloadOnlyMePosts(offset: number): void {
    const headers = AuthService.getHeaders();
    this.http.get<PostResponse>(`${environment.apiUrl}/api/feed/only-me?offset=${offset}`, { headers }).subscribe(
      (posts: PostResponse) => {
        this.newOnlyMePostsSource.next(posts);
        return posts;
      },
      (e) => {
        console.log(e);
        return null;
      });
  }

  public reloadSinglePost(game: string, matchId: string, userId: string): void {
    const headers = AuthService.getHeaders();
    this.http.get<SinglePostResponse>(`${environment.apiUrl}/api/feed/${game}/${matchId}/${userId}`, { headers: headers }).subscribe(
      (post: SinglePostResponse) => {
        this.singlePostSource.next(post);
        return post;
      },
      (e) => {
        console.log(e);
        return null;
      });
  }

  public reloadSuggestedUsers(): void {
    const headers = AuthService.getHeaders();
    this.http.get<SuggestedUsersResponse>(`${environment.apiUrl}/api/suggestions`, { headers: headers}).subscribe(
      (suggested: SuggestedUsersResponse) => {
        this.suggestedUsersSource.next(suggested);
        return suggested;
      },
      (e) => {
        console.log(e);
        return null;
      });
  }

  public async reloadUsersWhoLikedPost(gameName: string, matchId: string) {
    try {
      const response = await this.http.get<PostLikesResponse>(`${environment.apiUrl}/api/posts/v1/${gameName}/${matchId}/likes`, { headers: AuthService.getHeaders()}).toPromise();
      if (response.response) {
        return response?.response;
      }
    } catch (err) {
      console.log(err);
      return [];
    }
  }

  public async likePostRequest(gameName: string, matchId: string, participantIds: string[]) {
    try {
      await this.http.post(`${environment.apiUrl}/api/posts/v1/${gameName}/${matchId}/like`, { participantIds }, { headers: AuthService.getHeaders() }).toPromise();
    } catch (err) {
      console.error(err);
    }
  }

  public async reloadPostDescription(postsToRequest: Post[]) {
    try {
      const response = await this.http.post<PostDescriptionsResponse>(`${environment.apiUrl}/api/posts/v1/descriptions`,
        { posts: postsToRequest },
        { headers: AuthService.getHeaders()}).toPromise();
      if (response.response) {
        return response?.response;
      }
    } catch (err) {
      console.log(err);
      return [];
    }
  }

  public async editDescriptionRequest(gameName: string, matchId: string, description: string) {
    try {
      await this.http.post(`${environment.apiUrl}/api/posts/v1/${gameName}/${matchId}/description`,
        { description },
        { headers: AuthService.getHeaders()}).toPromise();
    } catch (err) {
      console.error(err);
    }
  }

  public async deleteDescriptionRequest(gameName: string, matchId: string) {
    try {
      await this.http.delete(`${environment.apiUrl}/api/posts/v1/${gameName}/${matchId}/description`,
        { headers: AuthService.getHeaders()}).toPromise();
    } catch (err) {
      console.error(err);
    }
  }
}
