<!-- TODO receipts need proper media="print" styling -->
<table class="table table-borderless">
	<thead>
		<th>id</th>
		<th>amount</th>
		<th>date</th>
	</thead>

	<tbody>
		<tr *ngFor="let receipt of receipts">
			<td><a [routerLink]="['/app/receipts', receipt.id]">{{ receipt.id }}</a></td>
			<td>{{ receipt.totalCents / 100 | currency }}</td>
			<td>{{ receipt.createdAt | date:'mediumDate' }}</td>
		</tr>
	</tbody>
</table>
