import { Component, OnInit, OnDestroy } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { NgForm } from '@angular/forms';
import { Subject } from 'rxjs';
import { User } from 'src/app/models/user.model';
import { UserService } from 'src/app/services/user.service';
import { PrivacySettingsMode } from 'leetify-shared-utils/enums';
import { Icon } from 'src/components/atoms/icon/icon.component';

@Component({
	selector: 'app-privacy-controls',
	templateUrl: './privacy-controls.component.html',
	styleUrls: ['./privacy-controls.component.scss'],
})
export class PrivacyControlsComponent implements OnDestroy, OnInit {
	public requestInProgress = false;
	public updateDetailsCheckRequestInProgress = true;
	public user: User;
	public selectedPrivacySettingsMode: PrivacySettingsMode;
	public readonly Icon = Icon;

	protected readonly ngUnsubscribe = new Subject<void>();

	public readonly radioOptions = [
		{ value: PrivacySettingsMode.PUBLIC, label: 'Public', description: 'Anyone can see your full profile or spectate you - this is how Leetify is designed to function.' },
		{ value: PrivacySettingsMode.FRIENDS, label: 'Only Steam Friends', description: 'For us to know your Steam friends, you must have the Leetify Steam bot added and your friends list visible.' },
		{ value: PrivacySettingsMode.PRIVATE, label: 'Only Me', description: 'Only you can see your match history and friends info on your profile. Nobody is able to spectate you.' },
	];

	public constructor(
		protected readonly userService: UserService,
	) {
		//
	}

	public updatePrivacySettings(form: NgForm): void {
		if (!form.form.value) return;
		this.userService.updatePrivacySettings(form.form.value.privacySettingsMode);
	}

	protected handleUser(user: User): void {
		this.user = user;
		this.selectedPrivacySettingsMode = user.privacySettings;
	}

	public ngOnInit(): void {
		this.userService.user$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((user) => this.handleUser(user));
		this.handleUser(this.userService.user);
	}

	public ngOnDestroy(): void {
		this.ngUnsubscribe.next();
		this.ngUnsubscribe.complete();
	}
}
