import { Component, OnDestroy, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthService } from 'src/app/auth/auth.service';
import { environment } from 'src/environments/environment';
import { ToastService } from 'src/app/toast/toast.service';
import { User } from 'src/app/models/user.model';
import { UserService } from 'src/app/services/user.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Router } from '@angular/router';

@Component({
	selector: 'leetify-common-discord-connect-button',
	templateUrl: './discord-connect-button.component.html',
	styleUrls: ['./discord-connect-button.component.scss'],
})
export class CommonDiscordConnectButtonComponent implements OnDestroy, OnInit {
	public discordLoginUrl = `${environment.apiUrl}/discord/login?jwt=${AuthService.getToken()}`;

	public isDiscordConnected: boolean;
	public user: User;

	protected readonly ngUnsubscribe = new Subject<void>();

	public constructor(
		protected readonly http: HttpClient,
		protected readonly toastService: ToastService,
		protected readonly userService: UserService,
		protected readonly router: Router,
	) {
		//
	}

	protected async getUserDiscord(): Promise<void> {
		const headers = AuthService.getHeaders();

		const data = await this.http.get<{ connected: boolean }>(`${environment.csApiUrl}/api/user/discord-connected`, { headers }).toPromise();

		this.isDiscordConnected = data.connected;
	}

	public async disconnectDiscord(): Promise<void> {
		const didDisconnectSuccessfully = await this.userService.disconnectDiscord();

		if (didDisconnectSuccessfully) {
			this.toastService.success('You have successfully removed your Discord account.');
			this.isDiscordConnected = false;
		} else {
			this.toastService.error('Failed to remove your Discord from your Leetify profile.');
		}
	}

	public ngOnInit(): void {
		const state = this.router.url.includes('lol') ? 'lol' : 'cs2';
		this.discordLoginUrl = `${environment.apiUrl}/discord/login?jwt=${AuthService.getToken()}&state=${state}`;
		this.user = this.userService.user;
		this.userService.user$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((user) => this.user = user);
		this.getUserDiscord();
	}

	public ngOnDestroy(): void {
		this.ngUnsubscribe.next();
		this.ngUnsubscribe.complete();
	}
}
