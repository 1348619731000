<div class="one-item-feed">
  <div class="elements">
    <div *ngIf="post">
      <div *ngFor="let team of post.teams">
        <div *ngIf="team.participants.length > 0">
          <post-card
            [user]="user"
            [post]="post"
            [teamId]="team.teamId"
            [shared]="true"
            [descriptions]="findDescriptionsForMatchId(post.matchId)">
          </post-card>
        </div>
      </div>
    </div>
    <div *ngIf="!user" class="join-container" [ngStyle]="{'background-image': 'url(' + backgroundImage + ')'}">
      <div class="text-container">
        <img class="leetify-logo" src="assets/images/leetify-logo-primary-white.svg" alt="Leetify" height="30">
        <div class="title-celebrate">Want to join the conversation and celebrate your gaming highlights?</div>
        <div class="subtitle-celebrate">Join your friends on Leetify and easily share your  clips, highlights and achievements!</div>
      </div>
      <div style="display: block; justify-content: flex-end">
        <div class="join-button text" (click)="goToSignup()">Join Leetify</div>
        <div class="join-subtext">
          <div class="already-have-account">Already have an account? </div>
          <div class="cursor-pointer already-have-account login" (click)="goToLogin()">Log in</div>
        </div>
      </div>
    </div>
  </div>
</div>
