import { AfterViewInit, Component, Inject, OnDestroy, OnInit, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DomSanitizer, SafeResourceUrl, Title } from '@angular/platform-browser';
import { HttpClient } from '@angular/common/http';
import { ToastService } from 'src/app/toast/toast.service';
import { PosthogTrackingValue } from 'leetify-shared-utils/enums';
import { TrackerService } from 'src/app/services/tracker.service';
import { ReceiptDTO } from 'leetify-shared-utils/dto';
import { UserService } from 'src/app/services/user.service';
import { User } from 'src/app/models/user.model';
import { SteamBotService } from 'src/app/services/steam-bot.service';
import { ViewHelper } from 'src/app/helpers/view.helper';
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/app/auth/auth.service';

const STEAM_COMMUNITY_BASE_URL = 'https://steamcommunity.com/profiles/';

@Component({
	selector: 'app-account',
	templateUrl: './account.component.html',
	styleUrls: ['./account.component.scss'],
})
export class AccountComponent implements AfterViewInit, OnDestroy, OnInit {
	public readonly onPlayerHeaderErrored = ViewHelper.onPlayerHeaderErrored;
	public readonly onProfilePictureErrored = ViewHelper.onProfilePictureErrored;
	public readonly ViewHelper = ViewHelper;

	public headerImageUrl: string = this.getFreshHeaderImageUrl();
	public profilePictureUrl: string = this.getFreshProfilePictureUrl();
	public receipts: ReceiptDTO[] = [];
	public steamApplicationUrl: SafeResourceUrl;
	public steamBotInviteLink: string;
	public steamCommunityUrl: string;
	public steamNotificationsEnabled: boolean;
	public steamSyncRequestInProgress = false;
	public nicknameSyncRequestInProgress = false;
	public user: User;
	public nickname: string;
	public isDiscordConnectedLoL: boolean;

	protected readonly ngUnsubscribe = new Subject<void>();

	protected isBrowser: boolean;
	protected steamNotificationsEnabledInterval: ReturnType<typeof setInterval>;
	protected steamBotRequested = false;

	public constructor(
		@Inject(PLATFORM_ID) protected readonly platformId: Record<string, any>,
		protected readonly http: HttpClient,
		protected readonly sanitizer: DomSanitizer,
		protected readonly steamBotService: SteamBotService,
		protected readonly titleService: Title,
		protected readonly toastService: ToastService,
		protected readonly trackerService: TrackerService,
		protected readonly userService: UserService,
		protected readonly authService: AuthService
	) {
		//
	}

	public trackSteamBotAdded(): void {
		this.trackerService.track(PosthogTrackingValue.STEAM_BOT_ADD);
	}

	public triggerSteamSync(): void {
		this.steamSyncRequestInProgress = true;
		
		const headers = AuthService.getHeaders();

		this.http.post(`${environment.csApiUrl}/api/steam/sync-metadata`, null, { headers }).subscribe(() => {
			this.toastService.success('Updated your nickname and avatar');
			this.userService.reloadUser();
			this.steamSyncRequestInProgress = false;
		}, (e) => {
			console.error(e);
			this.toastService.error('Couldn\'t update your nickname and avatar :(');
			this.steamSyncRequestInProgress = false;
		});
	}

	public triggerNicknameSave(): void {
		this.nicknameSyncRequestInProgress = true;

		this.userService.updateNickname(this.nickname).then(() => {
			this.userService.reloadUser();
			this.toastService.success('Nickname updated!');
		}).catch((err) => {
			console.error(err);
			this.toastService.error('Couldn\'t update nickname:(');
		});

		this.nicknameSyncRequestInProgress = false;
	}

	public uploadHeaderImage(files: FileList): void {
		const file = files.item(0);
		if (!file) return;

		this.userService.uploadHeaderImage(file).then(() => {
			this.headerImageUrl = this.getFreshHeaderImageUrl();
			this.toastService.success('Cover image updated!');
		}).catch((err) => {
			console.error(err);
			this.toastService.error('Couldn\'t upload cover image :(');
		});
	}

	public uploadProfilePicture(files: FileList): void {
		const file = files.item(0);
		if (!file) return;

		this.userService.uploadProfilePicture(file).then(() => {
			this.profilePictureUrl = this.getFreshProfilePictureUrl();


			this.userService.reloadUser();
			this.toastService.success('Profile picture updated!');
		}).catch((err) => {
			console.error(err);
			this.toastService.error('Couldn\'t upload profile picture :(');
		});
	}

	public deleteHeaderImage(): void {
		// eslint-disable-next-line no-alert
		if (!window.confirm('Are you sure you want to delete your current Profile Cover Image?')) return;

		this.userService.deleteHeaderImage().then(() => {
			this.headerImageUrl = this.getFreshHeaderImageUrl();
			this.toastService.success('Cover image deleted!');
		}).catch((err) => {
			console.error(err);
			this.toastService.error('Couldn\'t delete cover image :(');
		});
	}

	public copyLink(element: HTMLInputElement): void {
		element.select();
		window.document.execCommand('copy');
		this.toastService.success('Copied to clipboard!');
	}

	protected setUser(user: User): void {
		this.user = user;

		this.headerImageUrl = this.getFreshHeaderImageUrl();
		this.profilePictureUrl = this.getFreshProfilePictureUrl();
	}

	protected getFreshHeaderImageUrl(): string {
	 	return this.userService.cacheBustHeaderImage(this.userService.user?.id);
	}

	protected getFreshProfilePictureUrl(): string {
		return this.userService.cacheBustProfilePicture(this.userService.user?.id);
	}

	protected disconnectRiotAccount(): void {
		const areYouSure = confirm('Are you sure you want to disconnect your Riot account?');
		if (!areYouSure) return;
	}

	public ngOnInit(): void {
		this.isBrowser = isPlatformBrowser(this.platformId);

		this.trackerService.track(PosthogTrackingValue.PROFILE);
		this.titleService.setTitle('Account Settings - Leetify');

		this.setUser(this.userService.user);
		this.userService.user$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((user) => this.setUser(user));

		this.steamBotService.steamNotificationsEnabled$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((notificationsEnabled) => {
			this.steamNotificationsEnabled = notificationsEnabled;
			if (notificationsEnabled && this.steamNotificationsEnabledInterval) clearInterval(this.steamNotificationsEnabledInterval);

			if (!notificationsEnabled && !this.steamBotRequested) {
				this.steamBotRequested = true;
				this.steamBotService.getAvailableSteamBot();
			}
		});

		this.steamBotService.reloadSteamNotificationsEnabled();

		this.steamBotService.availableSteamBot$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((bot) => {
			this.steamApplicationUrl = this.sanitizer.bypassSecurityTrustUrl(bot.url);
			this.steamBotInviteLink = bot.inviteLink;
			this.steamCommunityUrl = `${STEAM_COMMUNITY_BASE_URL}${bot.botSteam64Id}`;
		});

		if (this.isBrowser) {
			this.steamNotificationsEnabledInterval = setInterval(() => {
				this.steamBotService.reloadSteamNotificationsEnabled();
			}, 5000);
		}

		const headers = AuthService.getHeaders();

		this.http.get<ReceiptDTO[]>(`${environment.csApiUrl}/api/pro/receipts`, { headers }).subscribe({ next: (receipts) => this.receipts = receipts });
	}

	public ngAfterViewInit(): void {
		ViewHelper.scrollToHashAnchor();
	}

	public ngOnDestroy(): void {
		clearInterval(this.steamNotificationsEnabledInterval);

		this.ngUnsubscribe.next();
		this.ngUnsubscribe.complete();
	}
}
