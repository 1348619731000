import { animate, style, transition, trigger } from '@angular/animations';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { Router, Scroll } from '@angular/router';
import { Icon } from 'src/components/atoms/icon/icon.component';
import { ToastService } from 'src/app/toast/toast.service';

@Component({
	selector: 'app-toast',
	templateUrl: './toast.component.html',
	styleUrls: ['./toast.component.scss'],
	animations: [
		trigger('inOutAnimation', [
			transition(':enter', [style({ transform: 'translateX(100%)', opacity: 0 }), animate('0.5s ease-out', style({ transform: 'translateX(0%)', opacity: 1 }))]),
			transition(':leave', [style({ transform: 'translateX(0%)', opacity: 1 }), animate('0.5s ease-in', style({ transform: 'translateX(100%)', opacity: 0 }))]),
		]),
	],
})
export class ToastComponent implements OnDestroy, OnInit {
	protected readonly Icon = Icon;
	protected readonly ngUnsubscribe = new Subject<void>();

	protected filteredToasts: any[] = [];

	public constructor(
		protected readonly router: Router,
		protected readonly toastService: ToastService,
	) {
		//
	}

	public dismissFocusArea(toast: any): void {
		this.toastService.remove(toast);
		toast.dismissCallback();
	}

	public interactCallback(toast: any): void {
		this.toastService.remove(toast);
		toast.interactCallback();
	}

	protected buildViewModel(): void {
		if (this.router.url.startsWith('/app/focus-areas')) {
			this.filteredToasts = this.toastService.toasts;
		} else {
			this.filteredToasts = this.toastService.toasts.filter((toast) => toast.type !== 'focusAreaPassed');
		}
	}

	public ngOnInit(): void {
		this.toastService.toastsUpdated$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => this.buildViewModel());

		this.router.events.pipe(
			takeUntil(this.ngUnsubscribe),
			filter((e): e is Scroll => e instanceof Scroll),
		).subscribe(() => this.buildViewModel);

		this.buildViewModel();
	}

	public ngOnDestroy(): void {
		this.ngUnsubscribe.next();
		this.ngUnsubscribe.complete();
	}
}
