@if (standoutPerTab[activeTab].achievement) {
  <div [class]="standoutPerTab[activeTab].class">
    @switch (standoutPerTab[activeTab].tier) {
      @case ('personal-best') {
        <img class="pb-decoration" src="assets/images/standout-decorations/pb-decoration.svg" alt="pb-decoration" />
      }
      <!-- S tier -->
      @case (0) {
        <img class="s-tier-decoration" src="assets/images/standout-decorations/s-tier-decoration.svg" alt="s-tier-decoration" />
      }
      <!-- A tier -->
      @case (1) {
        <img class="a-tier-decoration" src="assets/images/standout-decorations/a-tier-decoration.svg" alt="a-tier-decoration" />
      }
    }
    <div class="data">
      <div class="texts">
        <span class="title">{{ standoutPerTab[activeTab].title }}</span>
        <span class="description">{{ standoutPerTab[activeTab].description }}</span>
      </div>
    </div>
    <div class="icon">
      <img class="achievement-icon" [src]="getAchievementIcon(standoutPerTab[activeTab].achievement)" alt="achievement-icon" />
    </div>
  </div>
}
