import { Injectable } from '@angular/core';
import { AchievementDTO } from 'leetify-home-dtos/homepage-dto';

enum AchievementId {
  FORTY_BOMB = 'forty_bomb',
  THIRTY_BOMB = 'thirty_bomb',
  HIGHEST_RANK = 'highest_rank',
  LEADERBOARD_POSITION_CHANGE = 'leaderboard_position_change',
  LOCAL_LEGEND = 'local_legend',
  NINJA_DEFUSE = 'ninja_defuse',
  ONE_VS_FIVE_CLUTCH = 'one_vs_five_clutch',
  PERSONAL_BEST_ALL_TIME = 'personal_best_all_time',
  PERSONAL_BEST_SEASON = 'personal_best_season',
  RANKUP = 'rankup',
  RANKUP_PREMIER = 'rankup_premier',
  VERY_HIGH_LEETIFY_RATING = 'very_high_leetify_rating',
  WIN_STREAK = 'win_streak',
  ZERO_DEATHS_LONG_MATCH = 'zero_deaths_long_match',
  //missing CS
  //first match
  //club best
  //top 10% skill
  //exceptional highlight
  //13-0 win
  //club badge ownership
  //first cs2 match in a while
  //100th, 500th, 1000th game on leetify
  //100th(...) match with friend
  //first match on map X

  //missing LOL
  //first match
  //first LoL match in a while
  //100th, 500th, 1000th game on leetify
  //100th(...) match with friend
}

@Injectable({
  providedIn: 'root',
})
export class StandoutService {
  public constructor() {}

  protected achievementPriorityMap: { [key: string]: number } = {
    [AchievementId.HIGHEST_RANK]: 0,
    [AchievementId.PERSONAL_BEST_ALL_TIME]: 1,
    [AchievementId.ZERO_DEATHS_LONG_MATCH]: 2,
    [AchievementId.WIN_STREAK]: 3,
    [AchievementId.LOCAL_LEGEND]: 4,
    [AchievementId.VERY_HIGH_LEETIFY_RATING]: 5,
    [AchievementId.RANKUP]: 6,
    [AchievementId.RANKUP_PREMIER]: 7,
    [AchievementId.FORTY_BOMB]: 8,
    [AchievementId.ONE_VS_FIVE_CLUTCH]: 9,
    [AchievementId.NINJA_DEFUSE]: 10,
    [AchievementId.PERSONAL_BEST_SEASON]: 11,
    [AchievementId.THIRTY_BOMB]: 12,
    [AchievementId.LEADERBOARD_POSITION_CHANGE]: 13,
  };

  protected achievementKeywordMap: { [key: string]: string } = {
    [AchievementId.HIGHEST_RANK]: 'is the highest ',
    [AchievementId.PERSONAL_BEST_ALL_TIME]: 'Previous best was ',
    [AchievementId.ZERO_DEATHS_LONG_MATCH]: 'round game',
    [AchievementId.WIN_STREAK]: 'games in a row!',
    [AchievementId.LOCAL_LEGEND]: 'out of all your friends in the last 60 matches.',
    [AchievementId.VERY_HIGH_LEETIFY_RATING]: 'meaning you completely dominated the server!',
    [AchievementId.RANKUP]: "You've improved your skills and made it to",
    [AchievementId.RANKUP_PREMIER]: 'Your CS Rating is now ',
    [AchievementId.FORTY_BOMB]: 'The opponents must have been terrified when they saw you.',
    [AchievementId.ONE_VS_FIVE_CLUTCH]: '1v5 in Round ',
    [AchievementId.NINJA_DEFUSE]: 'You got a ninja defuse in Round ',
    [AchievementId.PERSONAL_BEST_SEASON]: 'of the year so far',
    [AchievementId.THIRTY_BOMB]: 'kills this game! Amazing!',
    [AchievementId.LEADERBOARD_POSITION_CHANGE]: ' higher on the ',
  };

  public getStandoutAchievement(achievements: AchievementDTO[]): AchievementDTO | void {
    const relevantAchievements: { achievement: AchievementDTO; matchedId: AchievementId }[] = [];

    achievements.forEach((achievement: AchievementDTO) => {
      for (const [achievementId, keyword] of Object.entries(this.achievementKeywordMap)) {
        if (achievement.description.includes(keyword)) {
          relevantAchievements.push({ achievement, matchedId: achievementId as AchievementId });
          break;
        }
      }
    });

    if (relevantAchievements.length) {
      const mostImportantAchievement = relevantAchievements.reduce((lowest, current) => {
        const currentPriority = this.achievementPriorityMap[current.matchedId];
        const lowestPriority = this.achievementPriorityMap[lowest.matchedId];
        return currentPriority < lowestPriority ? current : lowest;
      }, relevantAchievements[0]);
			return mostImportantAchievement.achievement;
    }
  }
}
