<section class="privacy-controls">
	<leetify-card>
		<ng-template leetifyCardHeaderInner>
			<div class="privacy-controls-header">
				<div class="privacy-controls-header-section">
					<leetify-icon [icon]="Icon.EYE_LOCK" class="privacy-controls-header-icon"></leetify-icon>
					<div>Privacy Controls</div>
				</div>
				<div class="privacy-controls-header-section header-tooltip">
					<!-- <app-tooltip type="privacy-controls" [placement]="['bottom']"></app-tooltip> -->
				</div>
			</div>
		</ng-template>

		<ng-template leetifyCardBodyInner>
			<p>Who's able to see your match history and friends information?</p>
			<form #privacySettingsForm="ngForm" (submit)="updatePrivacySettings(privacySettingsForm)">
				<leetify-radio id="public" name="privacySettingsMode" [options]="radioOptions" [(ngModel)]="selectedPrivacySettingsMode"></leetify-radio>
				<div class="submit-form-wrapper">
					<leetify-button [isSubmit]="true">Save Changes</leetify-button>
				</div>
			</form>
		</ng-template>
	</leetify-card>
</section>
