import { ProductName, SubscriptionDiscountIds } from 'leetify-shared-utils/enums';

export interface Plan {
	subscriptionDiscountId: string;
	title: string;
	description: string;
	discount: string;
	previousPrice: string | null;
	currentPrice: string;
	currentAnnualPrice: string;
	actionText: string;
	locked: boolean;
	spotlight: boolean;
	productName: string;
	specialEdition: boolean; // Collector's Edition is a Special Edition. One time payment
	buttonClass: string;
	iconUrl: string;

	features: {
		underConstruction?: boolean;
		description: string;
		excludeFromDowngrade?: boolean;
		showNewBadge?: boolean;
		title: string;
		warning?: boolean;
	}[];
}

export class Plans {
	static plansData: Plan[] = [
		{
			subscriptionDiscountId: SubscriptionDiscountIds.SUPER_EARLY_BIRD,
			productName: ProductName.PRO,
			title: 'Super Early Bird',
			description: 'Not available anymore',
			discount: '50% off for life',
			previousPrice: '',
			currentPrice: '2.99',
			currentAnnualPrice: '29.99',
			actionText: 'Get Pro',
			locked: true,
			spotlight: false,
			specialEdition: false,
			buttonClass: 'pro',
			iconUrl: '',
			features: [
				{
					title: 'We love you!',
					description:
						'Thank you for being part of the Super Early Bird flock — you get to keep the discount (as long as you keep your subscription)!',
				},
			],
		},
		{
			subscriptionDiscountId: null,
			productName: ProductName.PRO,
			title: 'Leetify Pro',
			description: '',
			discount: null,
			previousPrice: null,
			currentPrice: '5.99',
			currentAnnualPrice: '59.95',
			actionText: 'Get Pro',
			locked: false,
			spotlight: true,
			specialEdition: false,
			buttonClass: 'pro',
			iconUrl: 'assets/leetify/leetify-icon.svg',
			features: [
				{
					title: 'Data for Clubs',
					description:
						'Create and view data for your team or friend group. Find out what you\'re struggling with as a team and deep dive into the data for any player in your club.',
				},
				{
					title: 'Practice servers',
					description: `CS2 Practice Servers are currently very basic. We'll be updating the servers and adding additional server types as plugins become available for CS2.`,
				},
				{
					title: 'Manual demo upload',
					description: 'Upload any demo for Leetify analysis and add it to your data.',
				},
				{
					title: '2D Replay',
					description:
						'2D Replay lets you review demos directly in our product in a bird\'s eye perspective, making demo reviewing faster and more enjoyable.',
				},
				{
					title: '<img class="mr-2 allstar-logo-inline" src="/assets/icons/allstar-logo.svg" alt="Allstar"> Match Highlights',
					description: 'Get an automatic highlight clip when you make an outstanding play! We keep an eye out for if you have a standout round during your match and then send it to Allstar, who creates your highlight. It\'s then displayed right on your match page, so you can share it or just re-live the moment.',
				},
				{
					title: 'Analyze all your matches',
					description:
						'On our Free plan, we only automatically import matches for you as long as you\'ve used the product in the last week. With Leetify Pro, your matches are always imported regardless of activity so you don\'t get any gaps in your data history.',
				},
			],
		},
		{
			subscriptionDiscountId: null,
			productName: ProductName.COLLECTOR,
			title: "Founder's Edition",
			description: 'Carry us by becoming a Founder.',
			discount: null,
			previousPrice: null,
			currentPrice: '199.99',
			currentAnnualPrice: '199.99',
			actionText: 'Become a founder',
			locked: true,
			spotlight: true,
			specialEdition: true,
			buttonClass: 'founder',
			iconUrl: 'assets/leetify/leetify-logo-founder.svg',
			features: [
				{
					title: '12 months Leetify Pro + 50% discount',
					description:
						'Get all current and future Pro features at a fixed price of $2.99 / month for life on future renewals, even if you cancel the subscription.',
				},
				{
					title: "Profile on the Founder's Wall",
					description: "Become divinity and get your profile added to our interactive Founder's Wall",
				},
				{
					title: "4x free 12 month's Leetify Pro",
					description: "Give your friends 12 month's free Leetify Pro!",
				},
				{
					title: "Founder's Badge",
					description:
						'When you appear in matches, your name will have a special Leetify founder badge, telling everyone your status as a mythical being.',
				},
				{
					title: 'Exclusive Founder Profile',
					description: 'Get an exclusive Founder Profile similar to the ones we make for Pro Players to show off your skills.',
				},
				{
					title: "Maniac's CS:GO course",
					description: "Get Maniac's CS:GO course from GamerzClass, which is highly recommended by our team.",
				},
				{
					title: 'Hackathon',
					description:
						"You and the other Founders get to submit ideas for something CS:GO related we'll build. Everyone will get to vote for which project we should undertake. Scoped to maximum 3 days.",
				},
				{
					title: 'Special Discord Role & Channel',
					description: 'Get a Founder role on Discord and a private channel for WIP sneak peeks on new features.',
				},
			],
		},
	];

	static getProPlans(): Plan[] {
		return Plans.plansData.filter((p) => p.specialEdition === false);
	}

	static getSpecialPlans(): Plan[] {
		return Plans.plansData.filter((p) => p.specialEdition === true);
	}

	static getPlanByDiscount(discountId: SubscriptionDiscountIds): Plan {
		return Plans.plansData.find((p) => p.subscriptionDiscountId === discountId);
	}

	static getPlanByProductName(productName: ProductName): Plan {
		return Plans.plansData.find((p) => p.productName === productName);
	}
}
