<div *ngIf="highlight"
     class="highlight-video"
     [@slideInOut]="isVideoVisible ? 'in' : 'out'"
     [ngStyle]="{ 'margin-bottom': isVideoVisible ? '16px' : '0'}">
  <iframe *ngIf="isVideoVisible"
          [src]="highlight[0] | highlightIframeUrl: true"
          allow="autoplay; clipboard-write; fullscreen"
          class="video"
          allowfullscreen
          mozallowfullscreen
          webkitallowfullscreen
  ></iframe>
</div>
<div class="match-details" (click)="goToMatchList()" #matchTooltip="ngbTooltip" ngbTooltip="Go to match report"
     placement="bottom" tooltipClass="default-tooltip tooltip" style="cursor: pointer">
  <div class="splash-container">
    <div class="splash-image" [ngStyle]="{ 'background': splashStyle }">
      <div class="details">
        <img *ngIf="!isDeadlock()" [src]=icon class="icon" alt="gameIcon">
        <div class="card-title-name">{{ cardTitle }}</div>
        <div *ngIf="isLeague() || isCs()" class="card-subtitle league"> {{ cardSubtitle }}</div>
        <div *ngIf="isDeadlock()" class="card-subtitle deadlock"> {{ cardSubtitle }}</div>
      </div>
      <!-- achievements-->
      <div class="ah">
        <div *ngIf="achievements.length !== 0" class="achievements-highlights achievement"
             (mouseover)="switchTooltips(null, matchTooltip)"
             (mouseleave)="switchTooltips(matchTooltip, null)">
          <div *ngFor="let achievement of achievements; let i = index" >
            <img class="achievement-icon" [src]="getAchievementIcon(achievement.tier)"
                 [ngbTooltip]=getAchievementTitleAndDescription(i)
                 alt="achievement-icon">
          </div>
        </div>
        <!--highlights-->
        <div *ngIf="post.gameName !== 'league' && highlight.length !== 0" class="achievements-highlights highlight" (click)=showVideo($event)
             #highlightTooltip="ngbTooltip" ngbTooltip="Watch highlight" placement="bottom" tooltipClass="default-tooltip tooltip"
             (mouseover)="switchTooltips(highlightTooltip, matchTooltip)"
             (mouseleave)="switchTooltips(matchTooltip, highlightTooltip)">
          <img class="highlight-icon" src="assets/icons/highlight-icon.svg" alt="highlight-icon">
          <div *ngIf="highlight[0].description" class="highlight-title">{{ highlight[0].description }}</div>
        </div>
      </div>
    </div>
  </div>
  <div class="data">
    <div class="titles">
      <span *ngIf="isCs(); else notCs">SCORE</span>
      <ng-template #notCs>GAME LENGTH</ng-template>
      <span>KILLS</span>
      <span>ASSISTS</span>
      <span>DEATHS</span>
      <span *ngIf="isLeague()">TOTAL DMG</span>
      <span *ngIf="isDeadlock()">TOTAL SOULS</span>
      <span *ngIf="isCs()">LEETIFY RATING</span>
    </div>
    <div class="numbers">
      <div *ngIf="isCs(); else showGameLength">
        <span [ngStyle]="{'color': isWin() ? '#4DC49E' : '#FFF'}">{{ team.ownScore }}</span>
        <span class>:</span>
        <span [ngStyle]="{'color': !isWin() ? '#F77C60' : '#FFF'}">{{ team.enemyScore }}</span>
      </div>
      <ng-template #showGameLength> {{ gameLength }}</ng-template>
      <span>{{ team.participants[activeTab].stats.kills }}</span>
      <span>{{ team.participants[activeTab].stats.assists }}</span>
      <span>{{ team.participants[activeTab].stats.deaths }}</span>
      <span [ngStyle]="{color: !isCs() ? '#FFF' : (isNegativeRating() ? '#F77C60' : '#4DC49E')}">{{ mainGameStat }}</span>
    </div>
  </div>
</div>
