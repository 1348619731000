import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Subject } from 'rxjs';
import { AuthService } from 'src/app/auth/auth.service';
import { User } from 'src/app/models/user.model';
import { environment } from '../../environments/environment';
import { OnboardingStatus } from 'src/constants';
import { MatchmakingUserConnectedDTO } from 'leetify-shared-utils/dto';
import { ViewHelper } from '../helpers/view.helper';
import { PrivacySettingsMode } from 'leetify-shared-utils/enums';
import { ToastService } from '../toast/toast.service';

export { User };

@Injectable({
  providedIn: 'root',
})
export class UserService {
  public user$ = new Subject<any>();
  public user: User;

  public matchmakingUserConnected$ = new Subject<MatchmakingUserConnectedDTO>();
  public matchmakingUserConnected: MatchmakingUserConnectedDTO;

  private readonly headerImageCacheBusting: { [userId: string]: number } = {};
  private readonly profilePictureCacheBusting: { [userId: string]: number } = {};

  private readonly isBrowser: boolean;

  public selectedPrivacySettingsMode: PrivacySettingsMode;

  public constructor(
    @Inject(PLATFORM_ID) platformId: Record<string, any>,
    private http: HttpClient,
    private toastService: ToastService
  ) {
    this.isBrowser = isPlatformBrowser(platformId);

    this.user$.subscribe((user) => (this.user = user));
    this.matchmakingUserConnected$.subscribe((matchmakingUser) => (this.matchmakingUserConnected = matchmakingUser));
  }

  public reloadUser(): any {
    if (!this.isBrowser) return this.user$.next(null);

    const token = AuthService.getToken();
    const headers = new HttpHeaders({
      Authorization: `Bearer ${token}`,
    });

    return this.http.get<User>(`${environment.apiUrl}/api/user/v1/userinfo`, { headers }).subscribe(
      (user: any) => {
        this.user$.next(user);
        return user;
      },
      (e) => {
        if (e.status === 403) AuthService.removeToken();
        return null;
      }
    );
  }

  public async connectFaceit(): Promise<boolean> {
    const headers = AuthService.getHeaders();
    const response = await this.http
      .get<{ exists: boolean }>(`${environment.csApiUrl}/api/faceit/connect`, { headers })
      .toPromise()
      .catch(() => null);

    if (!response?.exists) {
      return false;
    }

    console.log('UserService connectFaceit reloadUser()');
    this.reloadUser();

    return true;
  }

  public updateOnboarding(onboardingStatus: OnboardingStatus, game: 'cs' | 'lol' = 'cs', referrerId?: string): Promise<any> {
    const headers = AuthService.getHeaders();
    if (game === 'cs') {
      return this.http
        .post(`${environment.csApiUrl}/api/user/update-onboarding`, { onboardingStatus }, { responseType: 'text', headers })
        .toPromise();
    } else if (game === 'lol') {
      return this.http
        .post(`${environment.lolApiUrl}/api/user/v1/update-onboarding`, { onboardingStatus, referrerId }, { responseType: 'text', headers })
        .toPromise();
    }
  }

  public async disconnectDiscord(): Promise<boolean> {
    const headers = AuthService.getHeaders();
    try {
      const response: any = await this.http.post(`${environment.csApiUrl}/api/discord/disconnect`, { headers }).toPromise();
      if (response.result !== 'ok') return false;

      this.reloadUser();
      return true;
    } catch {
      return false;
    }
  }

  public uploadHeaderImage(file: File): Promise<void> {
    const headers = AuthService.getHeaders();

    const formData = new FormData();
    formData.append('file', file);

    return this.http
      .post<void>(`${environment.csApiUrl}/api/user/header-image`, formData, { headers })
      .toPromise();
  }

  public deleteHeaderImage(): Promise<void> {
    const headers = AuthService.getHeaders();

    return this.http.delete<void>(`${environment.csApiUrl}/api/user/header-image`, { headers }).toPromise();
  }

  public uploadProfilePicture(file: File): Promise<void> {
    const headers = AuthService.getHeaders();

    const formData = new FormData();
    formData.append('file', file);

    return this.http
      .post<void>(`${environment.csApiUrl}/api/user/profile-picture`, formData, { headers })
      .toPromise();
  }

  public updateNickname(nickname: string): Promise<void> {
    const headers = AuthService.getHeaders();

    return this.http
      .post<void>(`${environment.csApiUrl}/api/user/nickname`, { nickname }, { headers })
      .toPromise();
  }

  public deleteProfilePicture(): Promise<void> {
    const headers = AuthService.getHeaders();

    return this.http.delete<void>(`${environment.csApiUrl}/api/user/profile-picture`, { headers }).toPromise();
  }

  public cacheBustHeaderImage(userId: string): string {
		if (userId) this.headerImageCacheBusting[userId] = +new Date();
		return this.getHeaderImageUrl(userId);
	}

  public cacheBustProfilePicture(userId: string): string {
		if (userId) this.profilePictureCacheBusting[userId] = +new Date();
		return this.getProfilePictureUrl(userId);
	}


  public getHeaderImageUrl(userId: string): string {
		if (!userId) return ViewHelper.PLAYER_HEADER_FALLBACK;

		const cacheBustingSuffix = this.headerImageCacheBusting[userId] ? `?${this.headerImageCacheBusting[userId]}` : '';
		// return `${environment.userUploadsPublicUrl}/header-images/${userId}.webp${cacheBustingSuffix}`; // TODO use this when we've got static URLs for header images
		return `${environment.csApiUrl}/api/user/header-image/${userId}${cacheBustingSuffix}`;
	}

  public getProfilePictureUrl(userId: string): string {
		if (!userId) return ViewHelper.PROFILE_PICTURE_FALLBACK

		const cacheBustingSuffix = this.profilePictureCacheBusting[userId] ? `?${this.profilePictureCacheBusting[userId]}` : '';

		return `${environment.csApiUrl}/api/user/profile-picture/${userId}${cacheBustingSuffix}`;
	}

  public updatePrivacySettings(privacySettingsMode: PrivacySettingsMode): void {
    const headers = AuthService.getHeaders();

		this.http.post(`${environment.csApiUrl}/api/user/update-privacy-settings`, { privacySettingsMode }, { headers }).subscribe(
			() => {
				this.toastService.success('Privacy settings successfully updated!');
				this.reloadUser();
			},
			() => this.toastService.error('Failed to update privacy settings.'),
		);
	}

  public async changeEmailAsPartOfOnboarding(email: string): Promise<string | void> {
		try {
			await this.http.post<unknown>(`${environment.csApiUrl}/api/user/change-email-as-part-of-onboarding`, { email }, { headers: AuthService.getHeaders() }).toPromise();
		} catch (err) {
			console.log(err);
			return err?.error?.error || 'Something went wrong :( Please try again.';
		}
	}

}
