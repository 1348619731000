import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PosthogTrackingValue, OnboardingStatus } from 'leetify-shared-utils/enums';
import { WebsocketService } from 'src/app/services/websocket.service';
import { UserService } from 'src/app/services/user.service';
import { TrackerService } from 'src/app/services/tracker.service';
import { ToastService } from 'src/app/toast/toast.service';
import { environment } from 'src/environments/environment';
import { AuthService } from '../auth/auth.service';

export enum MatchmakingConnectionResponse {
	SUCCESS = 1,
	INVALID_AUTH = 2,
	INVALID_MATCH_TOKEN = 3,
	TOO_MANY_RETRIES = 4,
	UNKNOWN_ERROR = 5,
}
@Injectable({
	providedIn: 'root',
})
export class ConnectMatchmakingService {
	public constructor(
		private readonly http: HttpClient,
		private readonly toastService: ToastService,
		private readonly trackerService: TrackerService,
		private readonly userService: UserService,
		private readonly websocketService: WebsocketService,
	) {
		//
	}

	public connectMatchmaking(user, steamAuthCode: string, steamLastShareCode: string): Promise<MatchmakingConnectionResponse> {
			const headers = AuthService.getHeaders();
		return new Promise((res) => {
			this.http
				.post(`${environment.csApiUrl}/api/matchmaking/connect`, { steamAuthCode: steamAuthCode.trim(), steamLastShareCode: steamLastShareCode.trim() }, { headers })
				.subscribe(
					(response) => {
						this.userService.matchmakingUserConnected$.next({
							steamLastShareCodeDate: (response as any).steamShareCodeDate,
							steamLastShareCode: steamLastShareCode.trim(),
							steamAuthCode: steamAuthCode.trim(),
						});
						this.toastService.success('Matchmaking was successfully added to Leetify data sources.');

						if (user.onboardingStatus !== OnboardingStatus.COMPLETED) {
							this.trackerService.track(PosthogTrackingValue.ONBOARDING_MATCHMAKING_CONNECTED);
						}

						this.websocketService.authenticate();
						this.websocketService.syncGames();
						res(MatchmakingConnectionResponse.SUCCESS);
					},
					(errorResponse: HttpErrorResponse) => {
						switch (errorResponse.status) {
							case 403:
								this.toastService.error('Oops! Your Authentication Code is invalid or is from a different Steam account than the one you connected on the previous step.');
								res(MatchmakingConnectionResponse.INVALID_AUTH);
								break;
							case 412:
								this.toastService.error('Oops! Your Match Token is invalid.');
								res(MatchmakingConnectionResponse.INVALID_MATCH_TOKEN);
								break;
							case 503:
								this.toastService.error('Uh-oh. Something is broken in CS right now. Give Valve some time to fix it.');
								res(MatchmakingConnectionResponse.UNKNOWN_ERROR);
								break;
							case 504:
								this.toastService.error('Too many retries with invalid Auth Code/ Match Token pair.');
								res(MatchmakingConnectionResponse.TOO_MANY_RETRIES);
								break;
							default:
								this.toastService.error('Oops! We were unable to connect your Steam to Leetify data sources.');
								res(MatchmakingConnectionResponse.TOO_MANY_RETRIES);
								break;
						}
					},
					() => {
						this.websocketService.authenticate();
						this.websocketService.syncGames();
						res(MatchmakingConnectionResponse.SUCCESS);
					},
				);
		});

	}
}
