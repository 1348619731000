<div class="post-card-with-kebab">
  <div class="post-card">
    <div class="top">
      <img [src]="gameIcon" class="icon" alt="gameIcon">
      <img [src]="'assets/icons/seps.svg'" alt="seps">
      <span class="tag">
      <i *ngIf="gameResult === 'win'"  class="text win-text win"> {{ gameResult }} </i>
      <i *ngIf="gameResult === 'loss'"  class="text loss-text loss"> {{ gameResult }} </i>
      <i *ngIf="gameResult === 'tie'"  class="text tie-text tie"> {{ gameResult }} </i>
    </span>
      <i class="date"> {{ date.month }}, {{ date.time }} </i>
      <!--kebab-->
      <img  *ngIf="!shared" [id]='post.matchId' src='assets/icons/kebab-menu.svg' class="kebab-menu" alt="kebab" (click)="toggleKebabMenu()">
    </div>
    <div class="players">
    <span *ngIf="players.length === 1" class="main-user-solo">
      <span class="user-data">
        <img class="user-icon" [src]="isLeague() ? getLeagueUserAvatar(players[0].avatar) : (players[0].avatar || 'assets/images/anon.png')" alt="user-avatar">
        <div>
          <span class="user-text"> {{ players[0].username }}</span>
          <span *ngIf="isLeague() && players[0].username.length < 8" class="user-tag"> #{{ userTag }}</span>
        </div>
        <app-rank-icon *ngIf="isCs() && isPremier()" dataSource="premier" [skillLevel]="players[0].stats.rank"></app-rank-icon>
        <img *ngIf="!isDeadlock() && !isPremier()"
             [src]="rankIcon" class="rank-icon" [class]="isCs() ? 'cs' : 'lol'"
             [ngbTooltip]="rankName"
             tooltipClass="default-tooltip"
             alt="rank">
      </span>
    </span>
      <!--additional users-->
      <div class="players-tab" *ngIf="players.length > 1">
        <div *ngFor="let player of players; let isFirst = first; let i = index" >
          <div [ngClass]="{'selected-player right-player': isTabSelected(i), 'player tab': !isTabSelected(i) }"
               [ngStyle]="{ 'width': calculateWidthOfTabs(i) }"
               (click)="activateTab(i)">
            <img class="user-icon" [src]="isLeague() ? getLeagueUserAvatar(players[i].avatar) : (players[i].avatar || 'assets/images/anon.png')" alt="user-avatar">
            <span *ngIf= "isTabSelected(i) || players.length <= 3" class="user-text"> {{ players[i].username }}</span>
            <span *ngIf="isLeague() && isTabSelected(i) && players[i].username.length < 8" class="user-tag"> #{{ lolStats.userTag }}</span>
            <app-rank-icon *ngIf="isCs() && isPremier()" dataSource="premier" [skillLevel]="players[i].stats.rank"></app-rank-icon>
            <img *ngIf="!isDeadlock() && isTabSelected(i) && !isPremier()"
                 [src]="rankIcon" class="rank-icon"  [class]="isCs() ? 'cs' : 'lol'"
                 [ngbTooltip]="rankName"
                 tooltipClass="default-tooltip"
                 alt="rank">
          </div>
        </div>
      </div>
    </div>
    <div class="panel">
      <!--description-->
      <textarea [id]="'textArea' + post.matchId" *ngIf="isEditingDescription"
                [class]="isEditingDescription ? 'edit-description' : 'description-text with-text'"
                maxlength="130"
                (keydown.enter)="onFinishEditingDescription()"
                (keydown.escape)="onEditDescriptionCancel()"
                [(ngModel)]="newDescription"
                (input)="onEditDescription()"
                (focus)="onEditDescriptionFocus()"
                [ngStyle]="{ 'font-size': fontSize[activeTab] + 'px' }">{{ newDescription }}
      </textarea>
      <leetify-expandable-vertical [isActive]="isDescriptionOpen">
        <div *ngIf=" !isInitialDescriptionLoaded && !isEditingDescription"
             [ngClass]="getInitialLoadOfDescriptions() ? 'description-text with-text' : 'description-text no-description'"
             [ngStyle]="{ 'font-size': fontSize[activeTab] + 'px' }">{{ getInitialLoadOfDescriptions() }}
        </div>
        <div *ngIf="isInitialDescriptionLoaded && !isEditingDescription"
             [ngClass]="postDescriptions[this.activeTab] ? 'description-text with-text' : 'description-text without-text'"
             [ngStyle]="{ 'font-size': fontSize[activeTab] + 'px' }"> {{ postDescriptions[this.activeTab] }}
        </div>
      </leetify-expandable-vertical>

      <standout
        [post]="post"
        [team]="team"
        [activeTab]="activeTab">
      </standout>

      <stats-card
        [post]="post"
        [team]="team"
        [activeTab]="activeTab"
        [icon]="icon"
        [splashImage]="splashImage"
        [splashStyle]="splashStyle"
        [cardTitle]="cardTitle"
        [cardSubtitle]="cardSubtitle"
        [achievements]="achievements"
        [highlight]="highlight"
        [gameLength]="gameLength"
        [kills]="kills"
        [assists]="assists"
        [deaths]="deaths"
        [mainGameStat]="mainGameStat">
      </stats-card>
    </div>
    <div class="footer">
    <span class="likes" [ngbTooltip]=getUsersWhoLiked() placement="top" tooltipClass="default-tooltip tooltip">
      <div *ngFor="let user of usersWhoLiked; let i = index">
        <img *ngIf="i <= 3" class="user-who-liked" [src]="user.userAvatar || 'assets/images/anon.png'" alt="user avatars">
      </div>
      <div class="number-of-likes">
        <i *ngIf="usersWhoLiked.length === 1" class="text">{{ usersWhoLiked.length }} Like</i>
        <i *ngIf="usersWhoLiked.length !== 1 && usersWhoLiked.length !== 0" class="text">{{ usersWhoLiked.length }} Likes</i>
      </div>
    </span>
      <div class="interactions">
        <img *ngIf="user" class="cursor-pointer icons" [src]="likeIcon" (click)="likePost()" alt="like">
        <img *ngIf="!shared" class="cursor-pointer icons" [src]="'assets/icons/share.svg'" (click)="copyLink()" alt="share">
      </div>
    </div>
  </div>

  <!--kebab menu-->
  <div>
    <div *ngIf="isKebabOpen" [class]="isMyPost() ? 'opened-kebab-menu logged' : 'opened-kebab-menu'">
      <div class="kebab-menu-item" (click)="copyLink()">
        <img src='assets/icons/copy-link.svg' class="kebab-icon" alt="copy link">
        <p class="menu-item-text">Copy link to post</p>
      </div>
      <div [id]="'edit' + post.matchId" *ngIf="isMyPost()" class="kebab-menu-item" (click)="onEditDescription()">
        <img [id]="'edit' + post.matchId" src='assets/icons/edit.svg' class="kebab-icon" alt="edit">
        <p [id]="'edit' + post.matchId" class="menu-item-text">Edit description of post</p>
      </div>
    </div>
  </div>
</div>
