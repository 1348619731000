<section *ngIf="billing && userPlan" class="card account-billing">
	<div class="card-header">
		<i class="leet-leetify"></i>
		<h2>Billing</h2>
	</div>

	<div class="card-body">
		<ng-container [ngSwitch]="statusView">

			<div *ngSwitchCase="StatusView.HAS_RENOWN_BUNDLE">
				<p>
					You are currently subscribed to Leetify Pro through Renown.gg.
				</p>
				<p *ngIf="billing?.renownBundle?.nextBilledAt">
					Next billing date: {{ billing.renownBundle.nextBilledAt | date: 'mediumDate' }}
				</p>
				Visit <a target="_blank" class="renown" href="https://renown.gg">Renown's site</a> to manage your subscription.
			</div>

			<div *ngSwitchCase="StatusView.NOT_SUBSCRIBED">
				<p>You are not currently subscribed to Leetify Pro.</p>
				<a href="/app/pro" class="btn btn-primary">Subscribe to Pro</a>
			</div>

			<div *ngSwitchCase="StatusView.CANCELED">
				<p>Your subscription was canceled.</p>
				<a href="/app/pro" class="btn btn-primary">Resubscribe</a>
			</div>

			<div *ngSwitchCase="StatusView.PAUSED">
				<p>Your subscription is currently paused and needs to be resumed.  Please contact support for help.</p>
				<a href="/app/support" class="btn btn-primary">Contact Support</a>
			</div>

			<div *ngSwitchCase="StatusView.SHOW_FULL">
				<div *ngIf="billing.status === SubscriptionStatus.PAST_DUE || billing.status === SubscriptionStatus.UNPAID"
					class="billing-warning danger">
					<leetify-icon [icon]="Icon.ALERT_CIRCLE_OUTLINE" class="icon"></leetify-icon>
					<p *ngIf="billing.status === SubscriptionStatus.PAST_DUE">
						Your current Pro subscription is set to renew but our last payment attempts failed. We will keep retrying for the next 3 days.
						Please <a (click)="updatePayment()">update your payment method</a> if it has changed.
					</p>
					<p *ngIf="billing.status === SubscriptionStatus.UNPAID">
						Your Pro subscription failed to renew. Please <a href="/app/support">contact support</a> for help or start a new subscription with us at the end of your Pro subscription.
					</p>
				</div>

				<p>
					Your plan: {{ userPlan.title }}{{ userPlan.discount ? ' (' + userPlan.discount + ')' : '' }}
					<span *ngIf="billing.status === SubscriptionStatus.TRIALING" class="badge badge-success">Free Period</span>
					- billed
					<span *ngIf="billing.interval === SubscriptionInterval.MONTHLY">{{ userPlan.currentPrice | currency: '$' }} / month.</span>
					<span *ngIf="billing.interval === SubscriptionInterval.ANNUAL">{{ userPlan.currentAnnualPrice | currency: '$' }} / year.</span>
				</p>

				<p *ngIf="!isProPlanExpired">
					Next payment attempt: {{ user.proPlanExpiresAt | date:'mediumDate' }}
				</p>

				<p *ngIf="!receipts.length">You will be able to change billing interval after your first subscription payment.</p>

				<div *ngIf="billing.status !== SubscriptionStatus.UNPAID" class="buttons">
					<a class="btn btn-sm btn-outline-primary" (click)="updatePayment()">
						Update payment method
					</a>

					<a
						*ngIf="receipts.length && billing.interval === SubscriptionInterval.MONTHLY"
						href="/app/account/update-plan"
						class="ml-2 btn btn-sm btn-outline-primary"
					>
						Switch to annual plan
					</a>
				</div>

				<p class="mt-3 mb-0">
					Want to go back to the Free version?
					<a href="/app/pro/pls-dont-go">Downgrade</a>
				</p>
			</div>

		</ng-container>
	</div>
</section>
