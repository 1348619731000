import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ReceiptDTO, UserBillingDTO } from 'leetify-shared-utils/dto';
import { ProductName, SubscriptionInterval, SubscriptionStatus } from 'leetify-shared-utils/enums';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { User, UserService } from 'src/app/services/user.service';
import { ToastService } from 'src/app/toast/toast.service';
import { Icon } from 'src/components/atoms/icon/icon.component';
import { Plan, Plans } from 'src/components/helpers/plans.helper';
import { BillingService } from 'src/components/services/billing/billing.service';

enum StatusView {
	NOT_SUBSCRIBED = 'not_subscribed',
	SHOW_FULL = 'show_full',
	CANCELED = 'canceled',
	HAS_RENOWN_BUNDLE = 'has_renown_bundle',
	PAUSED = 'paused',
}

@Component({
	selector: 'app-account-billing',
	templateUrl: './account-billing.component.html',
	styleUrls: ['./account-billing.component.scss'],
})
export class AccountBillingComponent implements OnInit {
	protected readonly ngUnsubscribe = new Subject<void>();
	public readonly SubscriptionStatus = SubscriptionStatus;
	public readonly SubscriptionInterval = SubscriptionInterval;
	public readonly StatusView = StatusView;
	public readonly Icon = Icon;

	@Input()
	public receipts: ReceiptDTO[] = [];

	public user: User;
	public userPlan: Plan;
	public billing: UserBillingDTO;
	public statusView: StatusView;
	public isProPlanExpired: boolean;

	public constructor(
		private readonly userService: UserService,
		private readonly billingService: BillingService,
		private readonly route: ActivatedRoute,
		private readonly toastService: ToastService,
	) {

	}

	public updatePayment(): void {
		if (!this.billing || !this.billing.productName || !this.billing.interval) return;

		this.billingService.getUpdatePaymentMethodUrl().then((url) => {
			if (url) window.location.href = url;
		});
	}

	protected handleBillingDetails(billing: UserBillingDTO): void {
		if (!billing) return;

		this.billing = billing;

		if (this.billing.discountId) {
			this.userPlan = Plans.getPlanByDiscount(this.billing.discountId);
		}

		if (!this.userPlan) {
			this.userPlan = Plans.plansData.find((p) => p.title === 'Leetify Pro');
		}

		// handle status if user has renown bundle
		if (billing.productName === ProductName.RENOWN_BUNDLE) {
			this.statusView = StatusView.HAS_RENOWN_BUNDLE;
			return;
		}

		switch (billing.status) {
			case SubscriptionStatus.ACTIVE:
			case SubscriptionStatus.TRIALING:
			case SubscriptionStatus.PAST_DUE:
			case SubscriptionStatus.UNPAID:
				this.statusView = StatusView.SHOW_FULL;
				break;
			case SubscriptionStatus.CANCELED:
				this.statusView = StatusView.CANCELED;
				break;
			case SubscriptionStatus.PAUSED:
				this.statusView = StatusView.PAUSED;
				break;
			default:
				this.statusView = StatusView.NOT_SUBSCRIBED;
		}
	}

	ngOnInit(): void {
		this.user = this.userService.user;
		this.userService.user$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((user) => {
			this.user = user;
			this.isProPlanExpired = new Date(user.proPlanExpiresAt) <= new Date();
		});

		this.billingService.billing$.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe((billing) => this.handleBillingDetails(billing));
		this.billingService.reloadUserBilling();

		const updatePaymentResult = this.route.snapshot.queryParamMap.get('update_payment');
		if (updatePaymentResult === 'success') this.toastService.success('Successfully updated your payment method. Your new payment method will be used on your next invoice.');
	}
}
