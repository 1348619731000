import { Component, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ActivatedRoute, Router, Scroll } from '@angular/router';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { OnboardingStatus, OnboardingStates, ONBOARDING_GREEN, ONBOARDING_RED, ONBOARDING_GRAY } from 'src/constants';
import { Icon } from 'src/components/atoms/icon/icon.component';
import { ExtendedIcon } from 'src/app/widgets/icon-extension/icon.component';
import { ToastService } from 'src/app/toast/toast.service';
import { User, UserService } from '../../services/user.service';
import { AuthService } from '../../auth/auth.service';
import { StorageKey } from 'src/app/services/storage.service';
import { SteamBotService } from 'src/app/services/steam-bot.service';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { TrackerService } from 'src/app/services/tracker.service';
import { environment } from '../../../environments/environment';
import { PosthogTrackingValue } from 'leetify-shared-utils/enums';
import { ConnectMatchmakingService, MatchmakingConnectionResponse } from 'src/app/services/connect-matchmaking.service';

const STEAM_COMMUNITY_BASE_URL = 'https://steamcommunity.com/profiles/';

@Component({
  selector: 'app-onboarding-layout',
  templateUrl: './onboarding-layout.component.html',
  styleUrls: ['./onboarding-layout.component.scss'],
})
export class OnboardingLayoutComponent implements OnDestroy, OnInit {
  protected readonly Icon = Icon;
  protected readonly ExtendedIcon = ExtendedIcon;
  protected readonly ngUnsubscribe = new Subject<void>();
  protected requestInProgress = false;
  protected user: User;
  protected isNewUser = true;
  protected mainText: string = '';
  protected secondaryText: string = '';
  protected footerText: string = 'You’re just a few minutes away from upgrading your skills faster';
  protected game: { name: string; crossFadeCss: string; nextState: OnboardingStates };
  protected games = [
    {
      name: 'cs',
      nextState: OnboardingStates.STEAM,
      crossFadeCss:
        'background-image: -webkit-cross-fade(url("assets/images/onboarding-images/CS-inactive.png"), url("assets/images/onboarding-images/CS.png"), 0%);',
    },
    {
      name: 'lol',
      nextState: OnboardingStates.RIOT,
      crossFadeCss:
        'background-image: -webkit-cross-fade(url("assets/images/onboarding-images/LoL-inactive.png"), url("assets/images/onboarding-images/LoL.png"), 0%);',
    },
  ];

  protected emailAddress: string;
  protected error: string;

  protected steps: { game: string; steps: { text: string; icon: Icon | ExtendedIcon; color: string; filled?: boolean; fillColor?: string }[] }[] = [
    {
      game: 'cs',
      steps: [
        {
          text: 'Create account',
          icon: Icon.CHECK_CIRCLE,
          color: ONBOARDING_GREEN,
        },
        {
          text: 'Choose game',
          icon: Icon.CHECK_CIRCLE,
          color: ONBOARDING_GREEN,
        },
        {
          text: 'Steam account',
          icon: Icon.STEAM,
          fillColor: `fill: ${ONBOARDING_RED}`,
          filled: true,
          color: ONBOARDING_RED,
        },
        {
          text: 'Data Source',
          icon: ExtendedIcon.SATELLITE,
          color: ONBOARDING_GRAY,
        },
        {
          text: 'Notifications',
          icon: ExtendedIcon.NOTIFICATION_BELL,
          color: ONBOARDING_GRAY,
        },
      ],
    },
    {
      game: 'lol',
      steps: [
        {
          text: 'Create account',
          icon: Icon.CHECK_CIRCLE,
          color: ONBOARDING_GREEN,
        },
        {
          text: 'Choose game',
          icon: Icon.CHECK_CIRCLE,
          color: ONBOARDING_GREEN,
        },
        {
          text: 'Riot account',
          icon: ExtendedIcon.RIOT,
          filled: true,
          fillColor: `fill: ${ONBOARDING_RED}`,
          color: ONBOARDING_RED,
        },
        {
          text: 'Notifications',
          icon: ExtendedIcon.NOTIFICATION_BELL,
          color: ONBOARDING_GRAY,
        },
      ],
    },
  ];
  protected selectedSteps:
    | { game: string; steps: { text: string; icon: Icon | ExtendedIcon; color: string; filled?: boolean; fillColor?: string }[] }
    | undefined;
  protected currentState: OnboardingStates = null;
  private checkIfSteamBotConnectedInterval: ReturnType<typeof setInterval>;

  public OnboardingStates = OnboardingStates;

  public steamCommunityUrl: string;
  public steamBotInviteLink: string;
  public steamNotificationsEnabled = true;
  public steamApplicationUrl: SafeResourceUrl;
  public areSteamBotsAvailable: boolean;
  public steamLastShareCode: string;
  public steamAuthCode: string;
  public isLastShareCodeValid = true;
  public isAuthCodeValid = true;
  public didSteamRejectShareCode = false;
  public navigationActive = false;

  public constructor(
    protected readonly router: Router,
    protected readonly authService: AuthService,
    protected userService: UserService,
    protected toastService: ToastService,
    protected steamBotService: SteamBotService,
    protected trackerService: TrackerService,
    private sanitizer: DomSanitizer,
    private route: ActivatedRoute,
    private localStorageService: LocalStorageService,
    private connectMatchmakingService: ConnectMatchmakingService
  ) {}

  async ngOnInit() {
    this.userService.user$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((user) => {
      this.user = user;
      this.readUser();
    });

    if (this.user) {
      this.readUser();
    } else {
      this.userService.reloadUser();
    }

    this.steamBotService.steamNotificationsEnabled$.subscribe(async (notificationsEnabled) => {
      this.steamNotificationsEnabled = notificationsEnabled;

      if (this.steamNotificationsEnabled && this.currentState === OnboardingStates.STEAM_BOT) {
        clearInterval(this.checkIfSteamBotConnectedInterval);

        this.localStorageService.removeItem(StorageKey.HOME_ONBOARDING_STATE);

        this.trackerService.track(PosthogTrackingValue.ONBOARDING_STEAM_BOT_CONNECTED);
        this.toastService.success('Great! You will now receive Steam bot notifications about your games.');
        this.trackerService.track(PosthogTrackingValue.ONBOARDING_COMPLETED);
        await this.userService.updateOnboarding(OnboardingStatus.COMPLETED, 'cs');
        window.location.href = `${environment.frontendBaseUrl}/home/`;
      }
    });

    this.steamBotService.availableSteamBot$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((bot) => {
      this.areSteamBotsAvailable = !!bot?.botSteam64Id;

      if (bot) {
        this.steamApplicationUrl = this.sanitizer.bypassSecurityTrustUrl(bot.url);
        this.steamCommunityUrl = `${STEAM_COMMUNITY_BASE_URL}${bot.botSteam64Id}`;
        this.steamBotInviteLink = bot.inviteLink;
      }
    });

    this.checkIfSteamBotConnectedInterval = setInterval(() => this.steamBotService.reloadSteamNotificationsEnabled(), 5000);
    this.steamBotService.reloadSteamNotificationsEnabled();

    // close mobile nav after clicking any link
    this.router.events.pipe(filter((e): e is Scroll => e instanceof Scroll)).subscribe(() => (this.navigationActive = false));
  }

  private readUser(): void {
    let lastState = this.localStorageService.getValue<OnboardingStates>(StorageKey.HOME_ONBOARDING_STATE);

    if (Object.values(OnboardingStates).includes(lastState)) {
      if ([
        OnboardingStates.DATA_SOURCE,
        OnboardingStates.STEAM,
        OnboardingStates.STEAM_BOT,
        OnboardingStates.MATCHMAKING,
      ].includes(lastState)) {
        this.selectedSteps = this.steps.find((step) => step.game === 'cs');
      }

      if ([
        OnboardingStates.RIOT,
        OnboardingStates.DISCORD,
      ].includes(lastState)) {
        this.selectedSteps = this.steps.find((step) => step.game === 'lol');
      }

      // This probably means user is coming back from connecting Steam:
      // Home -> Steam -> CS -> Home
      if (this.user.steam64Id && lastState === OnboardingStates.STEAM) {
        lastState = OnboardingStates.DATA_SOURCE;
        this.localStorageService.saveValue(StorageKey.HOME_ONBOARDING_STATE, lastState);
      }

      this.setState(lastState);

    // Assuming user is trying to Sign in via Steam
    } else if (!lastState && this.user.steam64Id && this.user.onboardingStatusCS !== OnboardingStatus.COMPLETED) {
      this.selectedSteps = this.steps.find((step) => step.game === 'cs');
      this.setState(OnboardingStates.DATA_SOURCE);

    } else if (this.user.email.startsWith('incomplete-signup') && this.user.email.endsWith('leetify.com')) {
      this.setState(OnboardingStates.NO_EMAIL);
      return;

    } else {
      this.setState(OnboardingStates.GAME_SELECTION);
    }

    if (this.user.onboardingStatusCS === OnboardingStatus.COMPLETED) {
      this.isNewUser = false;
      this.game = this.games.find((g) => g.name === 'lol');
    }

    if (this.user.onboardingStatusLOL === OnboardingStatus.COMPLETED) {
      this.isNewUser = false;
      this.game = this.games.find((g) => g.name === 'cs');
    }

    if (this.game) {
      this.selectedSteps = this.steps.find((step) => step.game === this.game.name);
      if (this.game.name === 'cs') this.setOnboardingStateCS(this.user);
      else if (this.game.name === 'lol') this.setOnboardingStateLOL(this.user);
    }

    this.route.queryParams.subscribe((params) => {
      const error = params['error'];
      const game = params['game'];
      if (error) {
        this.toastService.error(decodeURIComponent(error));
      }
      if (game) {
        this.game = this.games.find((g) => g.name == game);
        if (this.game.name === 'cs') this.setOnboardingStateCS(this.user);
        else if (this.game.name === 'lol') this.setOnboardingStateLOL(this.user);
      }
    });
  }

  private setOnboardingStateLOL(user): void {
    if (user.onboardingStatusLOL === OnboardingStatus.COMPLETED) {
      window.location.href = `${environment.frontendBaseUrl}/home`;
    } else if (user.onboardingStatusLOL === OnboardingStatus.ONBOARDING) {
      // check riot
      if (user.puuid) {
        // if puuid is set that means riot account is set
        this.setState(OnboardingStates.DISCORD);
        // check discord
        if (user.discordUserId) {
          this.toastService.success('Discord connected successfully.');
          // if user discord is set that means discord is connected
          this.userService.updateOnboarding(
            OnboardingStatus.COMPLETED,
            'lol',
            user.referrerId || this.localStorageService.getValue<string>(StorageKey.REFERRAL_CODE)
          );
          window.location.href = `${environment.frontendBaseUrl}/home/`;
        }
      }
    } else if (user.onboardingStatusLOL === OnboardingStatus.NEW) {
      console.log('Onboarding started..');
      this.setState(this.game.nextState);
    }
  }

  private setOnboardingStateCS(user): void {
    if (!user.steam64Id || (user.steam64Id && user.faceitId && user.onboardingStatusCS === OnboardingStatus.NEW)) {
      // Init onboarding for users who registered via email or FACEIT.
      this.trackerService.track(PosthogTrackingValue.ONBOARDING_STARTED);
      this.setState(OnboardingStates.STEAM);
    } else if (user.steam64Id && !user.faceitId && user.onboardingStatusCS === OnboardingStatus.NEW) {
      // Skip "Steam onboarding" state for "Connect Steam" button redirect.
      this.trackerService.track(PosthogTrackingValue.ONBOARDING_STEAM_CONNECTED);
      this.userService.updateOnboarding(OnboardingStatus.ONBOARDING, 'cs');
      this.setState(OnboardingStates.DATA_SOURCE);
    } else if (user.faceitId && user.onboardingStatusCS === OnboardingStatus.NEW) {
      // Skip "Steam onboarding" state for "Login via FACEIT" button redirect.
      this.trackerService.track(PosthogTrackingValue.ONBOARDING_FACEIT_CONNECTED);
      this.userService.updateOnboarding(OnboardingStatus.ONBOARDING, 'cs');
      this.setState(OnboardingStates.DATA_SOURCE);
    } else if ((user.steam64Id || user.faceitId) && user.onboardingStatusCS === OnboardingStatus.ONBOARDING) {
      this.setState(OnboardingStates.STEAM_BOT);
    } else if (user.onboardingStatusCS === OnboardingStatus.COMPLETED) {
      window.location.href = `${environment.frontendBaseUrl}/home/`;
    }
  }

  ngOnDestroy(): void {
    clearInterval(this.checkIfSteamBotConnectedInterval);
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  onHover(game: { name: string; crossFadeCss: string }, enter: boolean) {
    if (enter) {
      this.fade(game, 150, true);
    } else {
      this.fade(game, 150, false);
    }
  }

  fade(game: { name: string; crossFadeCss: string }, duration: number, fadeIn: boolean) {
    const regex = /(background-image:.*?cross-fade\(.*?,.*?, )(\d+)%/;
    const match = game.crossFadeCss.match(regex);

    if (!match) {
      console.error('Could not find the percentage in the CSS string.');
      return;
    }

    const initialPercentage = parseInt(match[2]);
    const finalPercentage = fadeIn ? 100 : 0;
    const stepTime = 5;
    const steps = duration / stepTime;
    const stepValue = (finalPercentage - initialPercentage) / steps;
    let stepsTaken = 0;

    let currentPercentage = initialPercentage;
    const interval = setInterval(() => {
      stepsTaken++;
      if (stepsTaken > steps) {
        currentPercentage = finalPercentage;
        clearInterval(interval);
        return;
      }
      if (fadeIn) {
        currentPercentage += stepValue;
        if (currentPercentage > finalPercentage) {
          currentPercentage = finalPercentage;
          clearInterval(interval);
        }
      } else {
        currentPercentage += stepValue;
        if (currentPercentage < finalPercentage) {
          currentPercentage = finalPercentage;
          clearInterval(interval);
        }
      }

      const updatedCSS = game.crossFadeCss.replace(regex, `$1${Math.round(currentPercentage)}%`);

      game.crossFadeCss = updatedCSS;
    }, stepTime);
  }

  selectGame(game: { name: string; crossFadeCss: string; nextState: OnboardingStates }) {
    this.game = game;
    this.selectedSteps = this.steps.find((step) => step.game === game.name);

    if (this.game.name === 'cs') this.setOnboardingStateCS(this.user);
    else if (this.game.name === 'lol') this.setOnboardingStateLOL(this.user);

    this.setState(game.nextState);
  }

  async onRiotClick() {
    await this.userService.updateOnboarding(OnboardingStatus.ONBOARDING, 'lol');
    // redirect to riot login
    window.location.href = `${environment.lolApiUrl}/api/user/v1/login?jwt=${AuthService.getToken()}`;
  }

  async onSteamClick() {
    await this.userService.updateOnboarding(OnboardingStatus.ONBOARDING, 'cs');
    // redirect to steam login
    this.trackerService.track(PosthogTrackingValue.ONBOARDING_STEAM_CONNECTED);
    window.location.href = `${environment.csApiUrl}/steam/connect?jwt=${AuthService.getToken()}`;
  }

  onDiscordClick() {
    window.location.href = `${environment.csApiUrl}/discord/login?jwt=${AuthService.getToken()}&state=lol`;
  }

  async onSkipDiscordClick() {
    await this.userService.updateOnboarding(OnboardingStatus.COMPLETED, 'lol');
    window.location.href = `${environment.frontendBaseUrl}/home`;
  }

  onSteamBotClick() {
    window.open(this.steamBotInviteLink);
  }

  onMMClick() {
    this.setState(OnboardingStates.MATCHMAKING);
  }

  async onFaceitClick() {
    const exists = await this.userService.connectFaceit();
    if (!exists) this.toastService.error('Could not connect Faceit :(');
    else {
      this.setState(OnboardingStates.STEAM_BOT);
      this.trackerService.track(PosthogTrackingValue.ONBOARDING_FACEIT_CONNECTED);
    }
  }

  public getShareCodeFromInput(steamLastShareCodeInput: string): string {
    const rungameLinkPattern = /^(steam?:)\/\/(rungame)\/(730)\/(76561202255233023)\/(\+csgo_download_match%20)/g;

    if (rungameLinkPattern.test(steamLastShareCodeInput)) {
      return steamLastShareCodeInput.replace(rungameLinkPattern, '');
    }

    return steamLastShareCodeInput;
  }

  public handleAuthCodeInput(steamAuthCode: string): void {
    const validAuthCodePattern = /^([A-Z0-9]{4}-)([A-Z0-9]{5}-)([A-Z0-9]{4})$/g;
    this.isAuthCodeValid = validAuthCodePattern.test(steamAuthCode);
  }

  public handleShareCodeInput(steamLastShareCode: string): void {
    this.steamLastShareCode = this.getShareCodeFromInput(steamLastShareCode);

    const validShareCodePattern = /^(CSGO?)((-)([a-zA-Z0-9]){5}){5}$/g;
    this.isLastShareCodeValid = validShareCodePattern.test(this.steamLastShareCode);
  }

  public async connectMatchMaking(): Promise<void> {
    const matchmakingConnectionResponse = await this.connectMatchmakingService.connectMatchmaking(
      this.user,
      this.steamAuthCode,
      this.steamLastShareCode
    );
    this.didSteamRejectShareCode = matchmakingConnectionResponse === MatchmakingConnectionResponse.INVALID_MATCH_TOKEN;
    if (matchmakingConnectionResponse === MatchmakingConnectionResponse.SUCCESS) this.setState(OnboardingStates.STEAM_BOT);
  }

  public setNickname(nickname: string): void {
    if (!this.user.nickname) this.userService.updateNickname(nickname);
  }

  setState(state: OnboardingStates) {
    this.localStorageService.saveValue(StorageKey.HOME_ONBOARDING_STATE, state);
    this.currentState = state;

    switch (state) {
      case OnboardingStates.NO_EMAIL:
        this.mainText = 'Please insert your email to continue';
        this.secondaryText = '';
        this.footerText = 'You’re just a few minutes away from upgrading your skills faster';
        break
      case OnboardingStates.GAME_SELECTION:
        this.mainText = 'Select your main game';
        this.secondaryText = 'to get you up and running, select a game to connect, you can easily add more games later';
        this.footerText = "You're just a few minutes away from upgrading your skills faster";
        break;
      case OnboardingStates.STEAM:
        this.mainText = 'Connect your Steam account';
        this.secondaryText = 'so we know who you are in the games we analyze';
        this.footerText = "You're just a few minutes away from upgrading your skills faster";
        this.selectedSteps.steps[2].color = ONBOARDING_RED;
        this.selectedSteps.steps[2].icon = Icon.STEAM;
        this.selectedSteps.steps[2].filled = true;
        this.selectedSteps.steps[2].fillColor = `fill: ${ONBOARDING_RED}`;
        this.selectedSteps.steps[3].color = ONBOARDING_GRAY;
        break;
      case OnboardingStates.RIOT:
        this.mainText = 'Connect your Riot account';
        this.secondaryText = 'so we know who you are in the games we analyze';
        this.footerText = "You're just a few minutes away from upgrading your skills faster";
        this.selectedSteps.steps[2].color = ONBOARDING_RED;
        this.selectedSteps.steps[2].icon = ExtendedIcon.RIOT;
        this.selectedSteps.steps[2].filled = true;
        this.selectedSteps.steps[2].fillColor = `fill: ${ONBOARDING_RED}`;
        this.selectedSteps.steps[3].color = ONBOARDING_GRAY;
        break;
      case OnboardingStates.DATA_SOURCE:
        this.setNickname(this.user.steamNickname);
        this.mainText = 'Connect a data source';
        this.secondaryText = 'so we can analyze the matches you play automatically! You can easily add more later.';
        this.footerText = "You're just a few minutes away from upgrading your skills faster";
        this.selectedSteps.steps[2].color = ONBOARDING_GREEN;
        this.selectedSteps.steps[2].icon = Icon.CHECK_CIRCLE;
        this.selectedSteps.steps[2].filled = false;
        this.selectedSteps.steps[2].fillColor = 'fill: transparent';
        this.selectedSteps.steps[3].color = ONBOARDING_RED;
        this.selectedSteps.steps[4].color = ONBOARDING_GRAY;
        break;
      case OnboardingStates.MATCHMAKING:
        this.mainText = 'Connect matchmaking';
        this.secondaryText = '';
        this.footerText = "You're just a few minutes away from upgrading your skills faster";
        this.steamAuthCode = '';
        this.steamLastShareCode = '';
        break;
      case OnboardingStates.STEAM_BOT:
        this.toastService.success('Data source connected successfully.');
        this.steamBotService.getAvailableSteamBot();
        this.mainText = 'Setup Steam notifications';
        this.secondaryText =
          'Add our Steam bot to have your matches automatically analysed and the link sent to you via Steam chat, so you can figure out why you won or lost in between your matches.';
        this.footerText = 'Your account is ready  🎉  Are you ready to start improving?';
        this.selectedSteps.steps[3].color = ONBOARDING_GREEN;
        this.selectedSteps.steps[3].icon = Icon.CHECK_CIRCLE;
        this.selectedSteps.steps[3].filled = false;
        this.selectedSteps.steps[3].fillColor = 'fill: transparent';
        this.selectedSteps.steps[4].color = ONBOARDING_RED;
        break;
      case OnboardingStates.DISCORD:
        this.toastService.success('Riot account connected successfully.');
        this.setNickname(this.user.riotUsername);
        this.mainText = 'Setup Discord notifications';
        this.secondaryText =
          'Add our Discord bot to have your matches automatically analysed and the link sent to you via Discord chat, so you can figure out why you won or lost in between your matches.';
        this.footerText = 'Your account is ready  🎉  Are you ready to start improving?';
        this.selectedSteps.steps[2].color = ONBOARDING_GREEN;
        this.selectedSteps.steps[2].icon = Icon.CHECK_CIRCLE;
        this.selectedSteps.steps[2].filled = false;
        this.selectedSteps.steps[2].fillColor = 'fill: transparent';
        this.selectedSteps.steps[3].color = ONBOARDING_RED;
        break;
      default:
        break;
    }
  }

  onLogout() {
    this.authService.logout();
    window.location.href = `${environment.frontendBaseUrl}/auth/login`;
  }

  onSupport() {
    window.location.href = `${environment.frontendBaseUrl}/app/support`;
  }

  protected toggleNavigation(value?: boolean): void {
    this.navigationActive = value === undefined ? !this.navigationActive : value;
  }

  protected onMainContentClick(e: MouseEvent): void {
    if (!this.navigationActive) return;

    e.preventDefault();
    e.stopPropagation();
    this.navigationActive = false;
  }

  protected async submitEmailAddress(): Promise<void> {
    this.requestInProgress = true;
    this.error = null;

    const email = (this.emailAddress || '').trim();

    if (!email || !email.match(/^[^@]+@[^@]+\.[^@]+$/)) {
      this.error = 'Please provide a valid email address.';
      this.requestInProgress = false;
      return;
    }

    const error = await this.userService.changeEmailAsPartOfOnboarding(email);
    if (error) {
      this.requestInProgress = false;
      this.error = error;
      return;
    }

    this.setState(OnboardingStates.GAME_SELECTION);

    this.toastService.success('Your email address was successfully set.');
  }
}
