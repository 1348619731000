<section class="lol-settings">
	<leetify-card>
		<ng-template leetifyCardHeaderInner>
			<div class="lol-settings-header">
				<div class="lol-settings-header-section">
					<leetify-icon [icon]="Icon.LOL_LOGO" class="lol-settings-header-icon"></leetify-icon>
					<div>LoL Discord</div>
				</div>
				<div class="lol-settings-header-section header-tooltip">
				</div>
			</div>
		</ng-template>

		@if(user?.discordUserId) {
			<ng-template leetifyCardBodyInner>
				<p>Do you want to receive Discord messages with your post-match reports?</p>
				<form #lolDiscordForm="ngForm" (submit)="updateDiscordNotifications(lolDiscordForm)">
					<leetify-radio id="public" name="lolDiscordNotifications" [options]="radioOptions" [(ngModel)]="selectedDiscordNotifications"></leetify-radio>
					<div class="submit-form-wrapper">
						<leetify-button [isSubmit]="true">Save Changes</leetify-button>
					</div>
				</form>
			</ng-template>
		} @else {
			<ng-template leetifyCardBodyInner>
				<p>Connect your Discord account to receive post-match reports.</p>
				<div class="discord-connect-controls">
						<a [href]="discordUrl" class="btn btn-lg btn-primary">
							<span class="connect-button-logos">
								<i class="leet-leetify icon-size"></i>
								<span class="mr-1 ml-1">+</span>
								<i class="fab fa-discord mr-2"></i>
							</span>
				
							<span>Connect Discord</span>
						</a>
				</div>
			</ng-template>
		}

	</leetify-card>
</section>
