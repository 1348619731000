import { Component, OnInit } from '@angular/core';
import { first, takeUntil } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { NgForm } from '@angular/forms';
import { Subject } from 'rxjs';
import { ToastService } from 'src/app/toast/toast.service';
import { User } from 'src/app/models/user.model';
import { UserService } from 'src/app/services/user.service';
import { AuthService } from 'src/app/auth/auth.service';
import { environment } from 'src/environments/environment';

interface Errors {
	currentPassword?: string;
	newEmail?: string;
	newPassword?: string;
}

@Component({
	selector: 'app-account-change-details',
	templateUrl: './change-details.component.html',
	styleUrls: ['./change-details.component.scss'],
})
export class ChangeDetailsComponent implements OnInit {
	public emailFormErrors: Errors = {};
	public hasPasswordSet: boolean;
	public passwordFormErrors: Errors = {};
	public requestInProgress = false;
	public updateDetailsCheckRequestInProgress = true;
	public user: User;

	protected readonly ngUnsubscribe = new Subject<void>();

	public constructor(
		protected readonly http: HttpClient,
		protected readonly toastService: ToastService,
		protected readonly userService: UserService,
	) {
		//
	}

	public submit(form: NgForm, errorsKey: 'emailFormErrors' | 'passwordFormErrors'): void {
		if (this.requestInProgress) return;

		this[errorsKey] = {};

		const { currentPassword, newEmail, newPassword } = form.value;

		if (!currentPassword) {
			this[errorsKey].currentPassword = 'Please enter your current password';
			return;
		}

		if (!newEmail && !newPassword) {
			this[errorsKey].newPassword = 'Please enter a new password or new email address';
			return;
		}

		/* eslint-disable no-alert */
		if (newPassword && !window.confirm('Are you sure that you want to change your password?')) return;
		if (newEmail && !window.confirm(`Are you sure that you want to change your email address to ${newEmail}?`)) return;
		/* eslint-enable no-alert */

		this.requestInProgress = true;

		const headers = AuthService.getHeaders();

		this.http.post<void>(`${environment.csApiUrl}/api/user/update-details`, { currentPassword, newEmail, newPassword }, { headers }).subscribe(() => {
			this.userService.user$.pipe(first()).subscribe(() => {
				if (newEmail && newPassword) this.toastService.success('Successfully changed email address and password.');
				else if (newEmail) this.toastService.success('Successfully changed email address.');
				else if (newPassword) this.toastService.success('Successfully changed password.');

				form.resetForm();
				this.requestInProgress = false;
			}, () => {
				this[errorsKey].currentPassword = 'Something went wrong :( Try again pls';
				this.requestInProgress = false;
			});

			this.userService.reloadUser();
		}, (err) => {
			console.error(err);

			if (err.status === 422) this[errorsKey] = err.error.errors;
			else this[errorsKey].currentPassword = 'Something went wrong :( Try again pls';

			this.requestInProgress = false;
		});
	}

	protected runUpdateDetailsCheck(): void {
		this.updateDetailsCheckRequestInProgress = true;

		const headers = AuthService.getHeaders();

		this.http.get<{ hasPasswordSet: boolean }>(`${environment.csApiUrl}/api/user/update-details-check`, { headers }).subscribe(({ hasPasswordSet }) => {
			this.hasPasswordSet = hasPasswordSet;
			this.updateDetailsCheckRequestInProgress = false;
		});
	}

	protected handleUser(user: User): void {
		this.user = user;
		if (!user) return;

		this.runUpdateDetailsCheck();
	}

	public ngOnInit(): void {
		this.userService.user$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((user) => this.handleUser(user));
		this.handleUser(this.userService.user);
	}

	public ngOnDestroy(): void {
		this.ngUnsubscribe.next();
		this.ngUnsubscribe.complete();
	}
}
