<ng-container [ngSwitch]="icon">
  <svg *ngSwitchCase="Icon.RIOT" width="16" height="16" viewBox="0 0 32 32" fill="none">
    <path
      [ngClass]="{ currentColor: withFill === false }"
      [style]="fillColor"
      d="M16.5345 25.7702L15.4436 22.96L25.9861 23.5021L25.5128 28L16.5345 25.7702ZM19.0606 4L4.30737 10.969L6.5891 21.4707H9.59839L9.07862 13.9584L9.5404 13.8152L11.3498 21.4704H14.4763L14.3606 12.3199L14.8224 12.1768L16.4037 21.4707H19.7138L20.4945 10.4175L20.956 10.2741L21.7751 21.4704H26.1522L27.6928 6.09992L19.0606 4Z"
      fill="white"
    />
  </svg>

  <svg *ngSwitchCase="Icon.NOTIFICATION_BELL" width="20" height="20" viewBox="0 0 20 20" fill="none">
    <path
      [ngClass]="{ currentColor: withFill === false }"
      [style]="fillColor"
      d="M17 13.5C15.8954 13.5 15 12.6046 15 11.5V8C15 5.2386 12.7614 3 10 3C7.2386 3 5 5.2386 5 8V11.5C5 12.6046 4.1046 13.5 3 13.5H17Z"
      [attr.stroke]="strokeColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path d="M7.55042 16C7.78212 17.1411 8.79052 18 10 18C11.2095 18 12.2179 17.1411 12.4496 16H7.55042Z" [attr.fill]="strokeColor" />
  </svg>

  <svg *ngSwitchCase="Icon.V_SHAPED_ARROW_LEFT" width="20" height="20" viewBox="0 0 20 20" fill="none">
    <path d="M12 3L7 10L12 17" stroke="#D2D2D2" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
  </svg>

  <svg *ngSwitchCase="Icon.V_SHAPED_ARROW_RIGHT" width="20" height="20" viewBox="0 0 20 20" fill="none">
    <path d="M8 3.5L13 10.5L8 17.5" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
  </svg>

  <svg *ngSwitchCase="Icon.ROBOT" width="21" height="20" viewBox="0 0 21 20" fill="none">
    <path d="M7.5 4V7" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    <path
      d="M7.5 4C8.05228 4 8.5 3.55228 8.5 3C8.5 2.44772 8.05228 2 7.5 2C6.94772 2 6.5 2.44772 6.5 3C6.5 3.55228 6.94772 4 7.5 4Z"
      stroke="white"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M13.9749 7.43903L12.5609 6.02503C12.4389 5.90203 12.2609 5.85303 12.0929 5.89203C11.9239 5.93203 11.7879 6.05603 11.7339 6.22103L11.0269 8.34203C10.9669 8.52203 11.0139 8.72003 11.1479 8.85403C11.2429 8.94903 11.3709 9.00003 11.5019 9.00003C11.5549 9.00003 11.6079 8.99203 11.6599 8.97403L13.7809 8.26703C13.9459 8.21203 14.0699 8.07703 14.1099 7.90803C14.1499 7.74003 14.0989 7.56203 13.9769 7.44003L13.9749 7.43903Z"
      fill="white"
    />
    <path
      d="M8.82802 7.028C8.82502 7.019 8.32402 7.009 8.32102 7H6.49902C4.84202 7 3.49902 8.343 3.49902 10V14C3.49902 15.657 4.84202 17 6.49902 17H14.499C16.156 17 17.499 15.657 17.499 14V10.76"
      stroke="white"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M15 8C16.6569 8 18 6.65685 18 5C18 3.34315 16.6569 2 15 2C13.3431 2 12 3.34315 12 5C12 6.65685 13.3431 8 15 8Z"
      fill="white"
      stroke="white"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M7.5 12.5C8.05228 12.5 8.5 12.0523 8.5 11.5C8.5 10.9477 8.05228 10.5 7.5 10.5C6.94772 10.5 6.5 10.9477 6.5 11.5C6.5 12.0523 6.94772 12.5 7.5 12.5Z"
      fill="white"
    />
    <path
      d="M13.5 12.5C14.0523 12.5 14.5 12.0523 14.5 11.5C14.5 10.9477 14.0523 10.5 13.5 10.5C12.9477 10.5 12.5 10.9477 12.5 11.5C12.5 12.0523 12.9477 12.5 13.5 12.5Z"
      fill="white"
    />
    <path d="M9.5 13H11.5C11.776 13 12 13.224 12 13.5C12 14.328 11.328 15 10.5 15C9.672 15 9 14.328 9 13.5C9 13.224 9.224 13 9.5 13Z" fill="white" />
  </svg>

  <svg *ngSwitchCase="Icon.SATELLITE" width="20" height="20" viewBox="0 0 20 20" fill="none">
    <path
      d="M4.7574 6.75739C2.4142 9.10059 2.4142 12.8995 4.7574 15.2427C7.1006 17.5859 10.8995 17.5859 13.2427 15.2427L4.7574 6.75739Z"
      [attr.stroke]="strokeColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path d="M9 11L11 9" [attr.stroke]="strokeColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M12 6C13.1046 6 14 6.8954 14 8" [attr.stroke]="strokeColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M12 2.5C15.0376 2.5 17.5 4.9624 17.5 8" [attr.stroke]="strokeColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
  </svg>

  <svg *ngSwitchCase="Icon.LOGOUT" viewBox="0 0 20 20">
    <path d="M6 13L3 10L6 7" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M12 10H3" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    <path
      [ngClass]="{ currentColor: withFill === false }"
      [style]="fillColor"
      d="M8.47499 15.475C8.45899 15.491 8.44099 15.501 8.42499 15.516C8.94699 16.4 9.89899 17 11 17H14C15.657 17 17 15.657 17 14V6C17 4.343 15.657 3 14 3H11C9.89899 3 8.94699 3.6 8.42499 4.484"
      stroke="white"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>

  <svg *ngSwitchCase="Icon.LIFE_RING" width="21" height="20" viewBox="0 0 21 20" fill="none">
    <path d="M12.6213 7.87869L15.4497 5.05029" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M12.6213 12.1213L15.4497 14.9497" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M8.37869 12.1213L5.55029 14.9497" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M8.37869 7.87869L5.55029 5.05029" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    <path
      d="M10.5 17C14.366 17 17.5 13.866 17.5 10C17.5 6.13401 14.366 3 10.5 3C6.63401 3 3.5 6.13401 3.5 10C3.5 13.866 6.63401 17 10.5 17Z"
      stroke="white"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M10.5 13C12.1569 13 13.5 11.6569 13.5 10C13.5 8.34315 12.1569 7 10.5 7C8.84315 7 7.5 8.34315 7.5 10C7.5 11.6569 8.84315 13 10.5 13Z"
      stroke="white"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>

  <svg *ngSwitchDefault viewBox="0 0 24 24" [ngSwitch]="icon">
    <path
      [ngClass]="{ currentColor: withFill === false }"
      [style]="fillColor"
      *ngSwitchCase="Icon.CHECK_CIRCLE"
      d="M10 16.5C13.866 16.5 17 13.366 17 9.5C17 5.63401 13.866 2.5 10 2.5C6.13401 2.5 3 5.63401 3 9.5C3 13.366 6.13401 16.5 10 16.5Z"
      [attr.stroke]="strokeColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      [ngClass]="{ currentColor: withFill === false }"
      [style]="fillColor"
      *ngSwitchCase="Icon.CHECK_CIRCLE"
      d="M7 10L9 12L13 7"
      [attr.stroke]="strokeColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</ng-container>
