import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Subject } from 'rxjs';
import { UserBillingDTO } from 'leetify-shared-utils/dto';
import { ToastService } from 'src/app/toast/toast.service';
import { User } from 'src/app/models/user.model';
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/app/auth/auth.service';

export {
	User,
};

@Injectable({
	providedIn: 'root',
})
export class BillingService {
	private billingSource = new Subject<UserBillingDTO>();
	public billing$ = this.billingSource.asObservable();

	public constructor(
		@Inject(PLATFORM_ID) platformId: Record<string, any>,
		private http: HttpClient,
		private toastService: ToastService,
	) {
	}

	public async getCheckoutUrl(productName: string): Promise<string> {
		try {
			const headers = AuthService.getHeaders();
			const data = await this.http.post(`${environment.csApiUrl}/api/stripe/purchase/${productName}`, {}, { headers }).toPromise();
			return (data as any)?.url;
		} catch (xhr) {
			switch (xhr?.status) {
				case 400: this.toastService.error('You selected a subscription that is no longer available on Leetify.  Please contact support for help for more information.'); break;
				case 406: this.toastService.error('You already have an active subscription with Leetify.  If this is an error, please contact support for help.'); break;
				default: this.toastService.error('An issue has occurred on Leetify side. Please try again later or contact support if this error persists.'); break;
			}
		}
	}

	public async getUpdatePaymentMethodUrl(): Promise<string> {
		try {
			const headers = AuthService.getHeaders();
			const data = await this.http.post(`${environment.csApiUrl}/api/stripe/update-payment`, {}, { headers }).toPromise();
			return (data as any)?.url;
		} catch (xhr) {
			switch (xhr?.status) {
				case 403: this.toastService.error('Error while trying to update your payment method. Are you sure you are logged in?'); break;
				default:
					this.toastService.error(`An error has occurred while trying to update your payment method. ${xhr?.error.errorMessage ?? ''}`);
					break;
			}
		}
	}

	public reloadUserBilling(): void {
		const headers = AuthService.getHeaders();
		this.http.get(`${environment.csApiUrl}/api/user/billing`, { headers }).subscribe({
			next: (response: UserBillingDTO) => { this.billingSource.next(response); },
			error: (err) => {
				switch (err.status) {
					case 500: return this.toastService.error(`Failed to fetch your billing details: ${err.errorMessage}.  Please contact support if this issue persists.`);
					default:
				}
			},
		});
	}
}
